import React from 'react';
import vector from '../../assets/Images/whychooseus/Vector.png'
import rounded from "../../assets/Images/whychooseus/wc bg[1].png"
import pixel from "../../assets/Images/whychooseus/Pixel 8 Pro Mockup Obsidian.png"
import service from "../../assets/Images/whychooseus/customer-service 1.png"
import imggg from "../../assets/Images/whychooseus/Avatar.png"
import imggg1 from "../../assets/Images/whychooseus/Avatar (1).png"
import imggg2 from "../../assets/Images/whychooseus/Avatar (2).png"
import bg from "../../assets/Images/whychooseus/frame.png"
import Marquee from 'react-fast-marquee';
import MarqueeBg from '../../assets/Images/whychooseus/marqueeBg.png';
import gwtl from '../../assets/Images/whychooseus/gw-tl.svg';
import gwbl from '../../assets/Images/whychooseus/gws-bl.svg';

// 
import sasi_img from "../../assets/Images/whychooseus/sasi_img.jpg"
import karthick_img from "../../assets/Images/whychooseus/karthick.jpg"

import growJson from "../../Json/Home/whyChooseUs/grow.json"
import Lottie from 'react-lottie';


const WhyChooseUs = ({theme}) => {


    const data1 = [
        { img : imggg,
            name : "Anil Kumar",
            mail : "Finance Manager",
            para : "CHITBID has completely transformed our chit fund management process. The automation and transparency it provides are unmatched."
        },
        { img : sasi_img,
            name : "Priya Sharma",
            mail : " Chit Fund Administrator",
            para : "We saw a significant reduction in administrative costs and time spent on manual tasks after switching to CHITBID."
        },
        { img : imggg,
            name : "Rajesh Mehta",
            mail : "CEO",
            para : "The real-time reporting and analytics features of CHITBID have given us valuable insights into our fund's performance."
        },
        { img : karthick_img,
            name : "Karthick",
            mail : "karthick@gmail.com",
            para : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam."
        },
        { img : imggg,
            name : "Riyas Khan",
            mail : "riyas@gmail.com",
            para : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam."
        },
    ]

    const data2 = [
        { img : imggg,
            name : "Arun karthick",
            mail : "arun@gmail.com",
            para : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam."
        },
        { img : imggg,
            name : "Palani Kumar",
            mail : "palani@gmail.com",
            para : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam."
        },
        { img : imggg,
            name : "Logesh",
            mail : "logesh@gmail.com",
            para : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam."
        },
        { img : imggg,
            name : "Surya",
            mail : "surya@gmail.com",
            para : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam."
        },
        { img : imggg,
            name : "Arish",
            mail : "arish@gmail.com",
            para : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam."
        },
    ]

    return (
        <div className={` h-[100%] ${theme === "dark" ? "bg-whychoosebg bg-cover" : "bg-white"}`}>

      
        <div className=" mx-auto pt-6 px-[8%]  animate-slideIn">
            <h2 className='mb-4 text-center'>
                <span className={`text-2xl font-bold md:text-4xl ${theme === "dark" ? "text-transparent bg-clip-text bg-gradient-to-r from-[#E26353] via-[#FBD4CE] to-[#E26353]" : "text-black"} `}>Why Choose Us?</span>
                </h2>
            <p className={`mb-4 text-center ${theme === "dark" ? "text-[#E3E5ED]" : "text-[#333333]"} `}>
                Experience secure, transparent, and rewarding chit fund investments tailored to your financial goals.
            </p>

            <div className="grid grid-cols-12 gap-4">

                <div className={`relative flex flex-col items-center justify-center col-span-12 overflow-hidden text-center ${theme === "dark" ? "bg-white" : "bg-[#FDE6E4]"}  rounded-lg lg:col-span-3`}>
                    <img src={bg} className='absolute w-auto h-auto' alt="" />
                    <p className="z-40 mb-2 text-4xl font-bold text-red-600">+1.6 M</p>
                    <p className="text-xl">Active Users</p>
                </div>



                <div className={`relative flex justify-between col-span-12 px-4 overflow-hidden text-left ${theme === "dark" ? "bg-white" : "bg-[#FDE6E4]"}  rounded-lg py-7 lg:px-9 lg:py-10 lg:col-span-9 `}>
                    <img src={rounded} className='h-[100%] lg:w-[60%] hidden lg:block  absolute top-0 right-0' alt="" />
                    <div className=''>
                        <p className="text-xl font-bold lg:text-2xl">We keep your funds safe</p>
                        <p className="mt-3 text-sm text-gray-600 lg:text-base">Your Money Is Secure With Us - We Prioritize <br /> The Safety Of Your Funds</p>
                    </div>
                    <img className="" src={vector} height={100} width={100} />
                </div>
                {/* <div className="flex flex-col justify-center p-6 text-center bg-pink-100 rounded-lg">
                    <p className="mb-2 text-xl font-semibold">Online Support</p>
                    <p className="text-gray-600">Facing Any Challenge? We're Available 24/7 To Assist You</p>
                </div> */}
            </div>

            
            <div className="grid h-auto grid-cols-12  gap-3 mt-4 ">
                <div className={`relative flex justify-between  col-span-12  overflow-hidden text-left ${theme === "dark" ? "bg-white" : "bg-[#FDE6E4]"}  rounded-lg lg:px-9 lg:col-span-9 `}>
                    <img src={gwtl} className='w-24 absolute top-0 left-0' alt="" />
                    <img src={gwbl} className='w-24 absolute bottom-0 left-0' alt="" />
                    <div className='grid grid-rows-2 md:grid-rows-1 grid-cols-12 px-4'>
                        <div className='flex flex-col justify-center col-span-12 md:col-span-6'>

                            <p className="text-3xl font-bold text-primaryColor ">Grow with us</p>
                            <p className="pt-3 text-base 2xl:text-lg text-[#333333] ">
                            "Grow with us at ChitBid, where we empower chit fund companies to expand their reach, enhance their operations, and achieve unprecedented success through innovative solutions, robust support, and a thriving community of like-minded partners."
                            </p>

                        </div>
                        <div className='col-span-12 md:col-span-6 relative w-[60%] md:w-[78%] 2xl:w-[60%] mx-auto md:ml-auto'>
                        <Lottie options={{loop: true, autoplay: true, animationData: growJson}}  className="h-full w-full ml-auto"/>
                        {/* <img className='w-48 xl:w-72 2xl:w-[295px]   absolute bottom-0 right-0' src={pixel} /> */}
                        </div>
                    </div>

                </div> 
                <div className='h-full col-span-12 lg:col-span-3 font-poppins'>
                    <div className={`col-span-12 py-2 text-center ${theme === "dark" ? "bg-white" : "bg-[#FDE6E4]"} rounded-lg lg:col-span-3`}>
                        <div className=' '>
                            <p className="mb-2 text-xl 2xl:text-2xl font-bold text-red-600">Online Support</p>
                            <p className="text-base 2xl:text-lg text-[#333333]">Facing any challenge? were available 24/7 to assist you</p>
                            <img src={service} className='w-20 h-20 mx-auto ' />
                        </div>
                    </div>
                    <div className={`col-span-12 py-2 mt-3 text-center ${theme === "dark" ? "bg-white" : "bg-[#FDE6E4]"} rounded-lg lg:col-span-3`}>
                        <div className=''>
                            <p className="mb-2 text-xl 2xl:text-2xl font-bold text-red-600">So many customers</p>
                            <p className="text-base 2xl:text-lg text-[#333333]">We have +300 customers in CHITBID</p>
                            <div className='flex p-2'>
                                <img src={imggg} className='object-cover rounded-full mx-auto w-14 h-14' />
                                <img src={imggg1} className='object-cover rounded-full mx-auto w-14 h-14' />
                                <img src={imggg2} className='object-cover rounded-full mx-auto w-14 h-14' />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        

        </div>


    {/* marquee */}
       <div className={`py-6 mt-4 animate-slideIn ${theme === "dark" ? "" : "bg-[#13224D]"}`}>

       <h2 className='mb-3 text-center'>
                <span className={`text-2xl font-bold md:text-4xl ${theme === "dark" ? "text-transparent bg-clip-text bg-gradient-to-r from-[#E26353] via-[#FBD4CE] to-[#E26353]" : "text-white"} `}>Trusted Testimonials</span>
                </h2>
                <p className={`max-w-2xl mx-auto mb-8 text-center ${theme === "dark" ? "text-[#E3E5ED]" : "text-[#E3E5ED]"}`}>
                Here’s what people are saying about us
                </p>

        <div className='relative'>

            {/* <img src={MarqueeLeftBg} className='absolute top-0 left-0 ' alt="" />
            <img src={MarqueeRightBg} className='absolute top-0 right-0' alt="" /> */}

        
        <Marquee
        className="text-white testing"
        direction="right"
        speed={70} // Adjust the speed to match scrollamount
        loop={0} // Infinite looping
        gradient={false} // Disable gradient
      >
       <div className='grid grid-cols-5 '>
        {data1.map((item) => (
            <div className={`w-[300px] md:w-[350px] font-poppins  px-4 py-4 me-4 md:me-5  relative ${theme === "dark" ? "" : "bg-white rounded-md"}`}>
                <img src={MarqueeBg} className={`absolute top-0 left-0 w-full h-full ${theme === "dark" ? "block" : "hidden"}`} alt="" />
                <div className='flex mb-3'>
                    <img src={item.img} className='w-10 h-10 rounded-full object-cover' alt="" />
                    <div className='ms-2'>
                        <h2 className={`${theme === "dark" ? "" : "text-black"}`}>{item.name}</h2>
                        <p className={`text-sm ${theme === "dark" ? "text-[#E3E5ED]" : "text-[#292929]"}  `}>{item.mail}</p>
                    </div>
                </div>
                <p className={`text-sm  ${theme === "dark" ? "text-[#E3E5ED]" : "text-[#292929]"}`}>{item.para}</p>
            </div>
        ))}
       </div>
      </Marquee>

      <Marquee
        className="mt-4 text-white testing"
        direction="left"
        speed={70} // Adjust the speed to match scrollamount
        loop={0} // Infinite looping
        gradient={false} // Disable gradient
      >
        <div className='grid grid-cols-5 '>
        {data2.map((item) => (
               <div className={`w-[300px] md:w-[350px] font-poppins  px-4 py-4 me-4 md:me-5  relative ${theme === "dark" ? "" : "bg-white rounded-md"}`}>
               <img src={MarqueeBg} className={`absolute top-0 left-0 w-full h-full ${theme === "dark" ? "block" : "hidden"}`} alt="" />
               <div className='flex mb-3'>
                   <img src={item.img} className='w-10 h-10 rounded-full object-cover' alt="" />
                   <div className='ms-2'>
                       <h2 className={`${theme === "dark" ? "" : "text-black"}`}>{item.name}</h2>
                       <p className={`text-sm ${theme === "dark" ? "text-[#E3E5ED]" : "text-[#292929]"}  `}>{item.mail}</p>
                   </div>
               </div>
               <p className={`text-sm  ${theme === "dark" ? "text-[#E3E5ED]" : "text-[#292929]"}`}>{item.para}</p>
           </div>
        ))}
       </div>
      </Marquee>

        </div>

        </div>

        </div>

    );
};

export default WhyChooseUs;
