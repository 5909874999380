import axios from "axios";
import siteUrls from "../api-config/ApiConfig";

export const mobileOTPSignup = async (payload) => {
    const mobileSignup = await axios.post(
        siteUrls.auth.mobileSignup,
        payload
    );
    return mobileSignup;
};

export const mobileOTPSignUpVerify = async (payload) => {
    const mobileSignup = await axios.post(
        siteUrls.auth.mobileSignupVerify,
        payload
    );
    return mobileSignup;
};
