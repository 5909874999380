import React, { useState } from 'react'
import gif from "../../assets/Images/Logo/anim@512p-50fps.gif"
// import aimationGif from "../../../assets/Images/BannerSection/Main Page V2 Setting change.gif"
import { Button } from 'flowbite-react'
import arrow from "../../assets/Images/BannerSection/arrow.png"
import { Link, useNavigate } from 'react-router-dom'

import animation_json from "../../Json/Home/BannerSection/Mainpage3.json"
import Lottie from 'react-lottie'
import Marquee from 'react-fast-marquee'
import { FeaturesListData } from '../../Data/FeaturesListData'
import OtpSendVisitor from '../../Components/OtpModalVisitor/OtpSendVisitor'

const BannerSection = ({theme}) => {

    const navigate = useNavigate()
    const [openOtpModal,setOpenOtpModal] = useState(false)

    const toggle =()=>{
        setOpenOtpModal(!openOtpModal)
    }

const imageData = [
    { index: 1, img1: {gif} },
    { index: 2, img1: {gif} },
    { index: 3, img1: {gif} },
    { index: 4, img1: {gif} },
    { index: 5, img1: {gif} },
]

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation_json,
  };

return (
    <>
    <div className={`${theme === "dark" ? "bg-bannerBg bg-center bg-cover" : "bg-[#FDE6E4]"}  h-full  pb-4 xl:pb-5 pt-[110px] xl:pt-[100px] 2xl:pt-[108px]`}>

        <div className='4xl:flex 4xl:flex-col 4xl:justify-between 4xl:h-full'>

            <div className='grid grid-cols-1  lg:grid-cols-2 gap-y-3 gap-x-2 px-[5%] xl:px-[8%]'>
                <div className='flex flex-col  justify-center pr-3 xl:pr-0 lg:mb-0'>
                    <h2 className={`text-2xl font-semibold ${theme === "dark" ? "text-white" : "text-black"} lg:text-[35px] xl:text-[39px] 2xl:text-[42px] font-poppins`}>
                        Welcome To <span className={`${theme === "dark" ? "text-transparent bg-clip-text bg-gradient-to-r from-[#E26353] via-[#FBD4CE] to-[#903225]" : "text-primaryColor"}`}>CHITBID</span> Your
                    </h2>
                    <h2 className={`text-2xl font-semibold ${theme === "dark" ? "text-white" : "text-black"} md:leading-[85px] lg:text-[35px] xl:text-[39px] 2xl:text-[42px] font-poppins`}>
                        Gateway To Efficient Chit
                    </h2>
                    <h2 className={`text-2xl font-semibold ${theme === "dark" ? "text-white" : "text-black"} lg:text-[35px] xl:text-[39px] 2xl:text-[42px] font-poppins`}>
                        Fund Management
                    </h2>
                    <p className={`text-sm xl:text-lg 2xl:text-xl font-poppins ${theme === "dark" ? "text-[#E3E5ED]" : "text-[#333333]"} mt-3 lg:mt-8 lg:pr-6`}>
                        Sign in to your onboarding portal and begin your journey with <span className="">CHITBID</span>. We're here to support you every step of the way.
                    </p>
                    <div className='flex items-center  mt-3 lg:mt-7'>
                        <Button color="failure" className='h-10 xl:h-12 p-0 xl:pt-1 bg-primaryColor me-2 lg:me-5 hover:bg-primaryColor'
                        onClick={()=>{setOpenOtpModal(true)
                            console.log("otp send modal opened");
                        }}>
                            Get Start For Free <img src={arrow} className='w-6 h-6 text-white' alt="" />
                        </Button>
                        <Button color="gray" onClick={()=>navigate("/RequestaDemo")} className='h-10 xl:h-12 p-0 xl:pt-1'>Request a Demo</Button>
                    </div>
                </div>
                <div className='w-[94%] 2xl:w-full ml-auto'>
                    {/* <video src={aimationGif} autoPlay={true} controls={false}></video> */}
                    {/* <img src={aimationGif}  className='w-full' alt="" /> */}
                    {/* <img src={animation_json}  className='w-full' alt="" /> */}
                    <Lottie options={defaultOptions} className="w-full"/>
                </div>
            </div>

            


            {/* <div className=' pt-3 xl:pb-4 2xl:pb-2  4xl:mb-14 4xl:pb-0 4xl:pt-0 lg:w-fit mx-auto overflow-hidden'>
                <div className={`rounded-md ${theme === "dark" ? "bg-white" : "bg-white"} flex py-2 px-3 overflow-x-scroll overflow-hidden w-full space-x-1 font-poppins hide-scrollbar`}>
                    <div className={`bg-primaryColor text-white rounded-md py-2 px-3 cursor-pointer min-w-fit`}>
                        <p>Live Auction</p>
                    </div>
                    <div className={`hover:bg-primaryColor hover:text-white rounded-md py-2 px-3 cursor-pointer transition-all duration-200 ease-in min-w-fit`}>
                        <p>E-KYC Verification</p>
                    </div>
                    <div className={`hover:bg-primaryColor hover:text-white rounded-md py-2 px-3 cursor-pointer transition-all duration-200 ease-in min-w-fit`}>
                        <p>Lead CRM</p>
                    </div>
                    <div className={`hover:bg-primaryColor hover:text-white rounded-md py-2 px-3 cursor-pointer transition-all duration-200 ease-in min-w-fit`}>
                        <p>Collection Management</p>
                    </div>
                    <div className={`hover:bg-primaryColor hover:text-white rounded-md py-2 px-3 cursor-pointer transition-all duration-200 ease-in min-w-fit`}>
                        <p>Payment Settlement</p>
                    </div>
                    <div className={`hover:bg-primaryColor hover:text-white rounded-md py-2 px-3 cursor-pointer transition-all duration-200 ease-in min-w-fit`}>
                        <p>AI for Customer Due Report</p>
                    </div>
                    <div className={`hover:bg-primaryColor hover:text-white rounded-md py-2 px-3 cursor-pointer transition-all duration-200 ease-in min-w-fit`}>
                        <p>Report Management</p>
                    </div>
                    <div className={`hover:bg-primaryColor hover:text-white rounded-md py-2 px-3 cursor-pointer transition-all duration-200 ease-in min-w-fit`}>
                        <p>Finance Management</p>
                    </div>
                </div>
            </div> */}

            </div>
     
    </div>

    <OtpSendVisitor isOpenModal={openOtpModal} toggle={toggle}/>
    </>
)
}

export default BannerSection
