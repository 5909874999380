import React, { useEffect, useRef, useState } from 'react'
import arrow from "../../assets/Images/BannerSection/arrow.png"
import { Button } from 'flowbite-react'
import { useNavigate, useParams } from 'react-router-dom'
import { FeaturesListData } from '../../Data/FeaturesListData'
import handIcon from "../../assets/Images/AboutUs/hand_icon.svg"

import aboutFirstImg from "../../assets/Images/AboutUs/about_first_img.png"
import benefitsLightIcon from "../../assets/Images/AboutUs/benefits_chitbid_icon_light.svg"
import benefitsDarkIcon from "../../assets/Images/AboutUs/benefits_chitbid_icon_dark.svg"
import historyLightIcon from "../../assets/Images/AboutUs/history_chitbid_icon_light.svg"
import historyDarkIcon from "../../assets/Images/AboutUs/history_chitbid_icon_dark.svg"
import Lottie from 'react-lottie'

const FeaturesPageSingle = ({ theme }) => {

  const { id } = useParams()
  const featureRef = useRef();
  const navigate = useNavigate();

  const [showSideBar, setShowSideBar] = useState(false);

  const [selectedFeature, setSelectedFeature] = useState(FeaturesListData.find(feature => feature.id === id) || FeaturesListData[0])

  const handleFeatureClick = (feature) => {
    setSelectedFeature(feature);
    navigate(`/Features/${feature.id}`)
  };

  // for side bar features
  useEffect(() => {
    const handleScroll = () => {
      let threshold = ""

      switch (true) {
        case window.innerWidth >= 1610:
          threshold = window.innerHeight / 1.85;
          break;
        case window.innerWidth >= 1536:
          threshold = window.innerHeight / 1.75;
          break;
        case window.innerWidth >= 1280:
          threshold = window.innerHeight / 1.35;
          break;
        case window.innerWidth >= 1024:
          threshold = window.innerHeight / 1.2;
          break;
      }

      if (window.scrollY > threshold && window.innerWidth >= 1024) {
        setShowSideBar(true);
      } else {
        setShowSideBar(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  // const feature = FeaturesListData.find(feature => feature.id === id);

  useEffect(() => {
    // if (featureRef.current) {
    //   featureRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    // }
    setTimeout(() => {
      setSelectedFeature(FeaturesListData.find(feature => feature.id === id) || FeaturesListData[0])
      if (featureRef.current) {
        featureRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
      }
    }, 100);
  }, [id]);

  return (
    <>
      <div className={`px-[8%] pb-5 md:pb-0 pt-28 md:pt-[105]   ${theme === "dark" ? "bg-featuresTopBg bg-cover" : "bg-lightBgTop bg-[#FDE6E4]"} `}>

        <div className='grid grid-cols-12 '>

          <div className='col-span-12  lg:col-span-7 flex flex-col justify-center'>
            <h2 className={`text-xl md:text-2xl xl:text-3xl 4xl:text-[42px] md:leading-10 4xl:leading-[50px] font-semibold font-poppins capitalize  ${theme === "dark" ? "text-white" : "text-black"}  `}>{selectedFeature.headerTitle}</h2>
            <p className={`text-sm xl:text-xl mt-3  ${theme === "dark" ? "text-[#E3E5ED]" : "text-[#333333]"} font-poppins `}>{selectedFeature.headerPara}</p>
            <div className='flex items-center mt-3 lg:mt-7'>
              <Button color="failure" className='px-0 md:pt-1 h-11 md:h-12 bg-primaryColor me-1 lg:me-5 hover:bg-primaryColor'>Get Start For Free <img src={arrow} className='w-6 h-6 text-white' alt="" /></Button>
              <Button color="gray" className='h-10 p-0 md:pt-1 md:h-12'>Request a Demo</Button>

            </div>
          </div>

          <div className='  col-span-12  lg:col-span-5 ml-auto w-[92%] h-80 4xl:h-[400px]  overflow-hidden'>
            <Lottie options={{ loop: true, autoplay: true, animationData: selectedFeature.headerTopImg }} className="h-full w-full" />
            {/* <img src={`${selectedFeature.headerTopImg}`} className='w-full h-full object-center' alt="No Image" /> */}
          </div>

        </div>

        {/* list of features */}
        <div className={`pb-4 pt-1 z-50 `}>
          <div className={`flex px-2 ${theme === "dark" ? "bg-white" : "bg-white"} rounded-md  `}>
            <div className={`flex py-2  overflow-x-scroll w-full space-x-1 font-poppins hide-scrollbar`}>

              {FeaturesListData.map((item) => (
                <div
                  key={item.id}
                  ref={item.id === id ? featureRef : null}
                  onClick={() => handleFeatureClick(item)}
                  className={`${theme === "dark" ? "hover:bg-[#c74132d4]  hover:text-white" : "hover:bg-[#13224dd8] hover:text-white"} group ${item.id === selectedFeature.id ? `${theme === "dark" ? "bg-primaryColor hover:bg-primaryColor  text-white" : "bg-[#13224d] text-white"}` : "bg-white text-black"}  flex items-center   rounded-md py-2 px-3 cursor-pointer min-w-fit`}>
                  <img src={item.icon} className={`group-hover:filter group-hover:invert ${item.id === id ? "invert" : ""}`} alt="" />
                  <p className={`text-sm 2xl:text-lg  ms-2 font-poppins `}>{item.title}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

      </div>

      <div className='relative top-0 left-0'>
        <div className={` ${theme === "dark" ? "bg-howItWorksBg bg-cover" : "bg-white"} py-14   flex`}>

          {/* right side full details */}
          <div className={`flex-1 ${showSideBar ? "px-[3%] me-52 " : "px-[8%]"}`}>
            <div className=''>
              <div className="">

                <h2 className={`mb-4 text-center text-2xl xl:text-2xl 2xl:text-4xl  font-bold ${theme === "dark" ? "text-white" : "text-black"} `}>
                  {selectedFeature.title}<span className={`ms-1 text-2xl font-bold xl:text-2xl 2xl:text-4xl  ${theme === "dark" ? " text-transparent bg-clip-text bg-gradient-to-r from-[#E26353] via-[#FBD4CE] to-[#E26353]" : "text-transparent bg-clip-text bg-gradient-to-r from-[#E26353] via-[#EE7424] to-[#903225]"}`}>Process</span>
                </h2>
                <p className={`max-w-2xl mx-auto   text-center ${theme === "dark" ? "text-[#E3E5ED]" : "text-[#333333]"} `}>{selectedFeature.para}</p>

              </div>

              {/* Features Steps */}
              <div className={`gap-4 md:gap-5 4xl:gap-10 mt-5 grid mx-auto
          ${selectedFeature.process.length === 1 ? "grid-cols-1" : selectedFeature.process.length === 2 ? "grid-cols-1 md:grid-cols-2 max-w-3xl 2xl:max-w-4xl 4xl:max-w-5xl" : selectedFeature.process.length === 3 ? "grid-cols-1 md:grid-cols-2 lg:grid-cols-3 4xl:max-w-7xl" : "grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
                }`}>
                {selectedFeature.process && selectedFeature.process.length > 0 &&
                  selectedFeature.process.map((item, index) => (
                    <div key={index} className={`hover:scale-105 duration-300 ease-in-out group p-3 2xl:p-4 rounded-xl font-poppins hover:border-[1px] hover:border-primaryColor   ${theme === "dark" ? "bg-white text-black" : "border-[2px] border-[#d2d1d1] bg-[#FDE6E4]"}`}>
                      <img src={item.processIcon} alt="" className="w-12" />
                      <h2 className="text-xl 2xl:text-2xl font-bold  mt-2 group-hover:text-primaryColor duration-200">{item.title}</h2>
                      <p className="text-sm 2xl:text-lg text-[#666666]  mt-1">{item.para}</p>
                    </div>
                  ))
                }
              </div>

              {/* step one */}
              {selectedFeature.processStep1 && selectedFeature.processStep1.length > 0 ? (
                <div className={`  pt-14 `}>

                  {selectedFeature.processStep1 && selectedFeature.processStep1.length > 0 && selectedFeature.processStep1.map((item) => (
                    <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-6'>
                      {/* <img src={item.leftImg} alt="" /> */}
                      <Lottie options={{ loop: true, autoplay: true, animationData: item.leftImg }} className="h-full w-full" />
                      <div className='flex flex-col justify-center'>
                        {theme === "dark" ?
                          <img src={item.rightTitleImgDark} className='w-40' alt="" /> :
                          <img src={item.rightTitleImgLight} className='w-40' alt="" />
                        }
                        <h2 className={`${theme === "dark" ? "text-white" : "text-black"} capitalize mt-6 font-poppins font-bold text-3xl 2xl:text-4xl`}>{item.rightTitle}</h2>
                        <div className='mt-4 2xl:mt-5'>
                          {item.rightDesc.map((item) => (
                            <div className='flex items-start mt-3 2xl:mt-5'>
                              <img src={handIcon} alt="" />
                              <p className={`${theme === "dark" ? "text-white" : "text-[#333333]"} ms-2 font-poppins text-lg 2xl:text-2xl`}>{item.para}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}

                </div>
              ) : ""}

              {/* step two */}
              {selectedFeature.processStep2 && selectedFeature.processStep2.length > 0 ? (
                <div className={`  pt-14 `}>

                  {selectedFeature.processStep2 && selectedFeature.processStep2.length > 0 && selectedFeature.processStep2.map((item) => (
                    <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-6'>

                      <div className='flex flex-col justify-center'>
                        {theme === "dark" ?
                          <img src={item.leftTitleImgDark} className={`${item.titleImgWidth}`} alt="" /> :
                          <img src={item.leftTitleImgLight} className={`${item.titleImgWidth}`} alt="" />
                        }
                        <h2 className={`${theme === "dark" ? "text-white" : "text-black"} capitalize mt-6 font-poppins font-bold text-3xl 2xl:text-4xl`}>{item.leftTitle}</h2>
                        <div className='mt-4 2xl:mt-5'>
                          {item.leftDesc.map((item) => (
                            <div className='flex items-start mt-3 2xl:mt-5'>
                              <img src={handIcon} alt="" />
                              <p className={`${theme === "dark" ? "text-white" : "text-[#333333]"} ms-2 font-poppins text-lg 2xl:text-2xl`}>{item.para}</p>
                            </div>
                          ))}
                        </div>
                      </div>

                      {/* {theme === "dark" ?
                        <img src={item.rightImgDark} className='ml-auto' alt="" /> :
                        <img src={item.rightImgLight} className='ml-auto' alt="" />
                      } */}
                      <Lottie options={{ loop: true, autoplay: true, animationData: item.rightImg }} className="h-full w-full" />
                    </div>
                  ))}

                </div>
              ) : ""}

              {/* step three */}
              {selectedFeature.processStep3 && selectedFeature.processStep3.length > 0 ? (
                <div className={`  pt-14 `}>

                  {selectedFeature.processStep3 && selectedFeature.processStep3.length > 0 && selectedFeature.processStep3.map((item) => (
                    <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-6'>
                      {/* <img src={item.leftImg} className='w-full' alt="" /> */}
                      <Lottie options={{ loop: true, autoplay: true, animationData: item.leftImg }} className="h-full w-full" />
                      <div className='flex flex-col justify-center'>
                        {theme === "dark" ?
                          <img src={item.rightTitleImgDark} className='w-48' alt="" /> :
                          <img src={item.rightTitleImgLight} className='w-48' alt="" />
                        }
                        <h2 className={`${theme === "dark" ? "text-white" : "text-black"} capitalize mt-6 font-poppins font-bold text-3xl 2xl:text-4xl`}>{item.rightTitle}</h2>
                        <div className='mt-4 2xl:mt-5'>
                          {item.rightDesc.map((item) => (
                            <div className='flex items-start mt-3 2xl:mt-5'>
                              <img src={handIcon} alt="" />
                              <p className={`${theme === "dark" ? "text-white" : "text-[#333333]"} ms-2 font-poppins text-lg 2xl:text-2xl`}>{item.para}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}

                </div>
              ) : ""}

              {/* step four */}
              {selectedFeature.processStep4 && selectedFeature.processStep4.length > 0 ? (
                <div className={`  pt-14 `}>

                  {selectedFeature.processStep4 && selectedFeature.processStep4.length > 0 && selectedFeature.processStep4.map((item) => (
                    <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-6'>

                      <div className='flex flex-col justify-center'>
                        {theme === "dark" ?
                          <img src={item.leftTitleImgDark} className='w-72' alt="" /> :
                          <img src={item.leftTitleImgLight} className='w-72' alt="" />
                        }
                        <h2 className={`${theme === "dark" ? "text-white" : "text-black"} capitalize mt-6 font-poppins font-bold text-3xl 2xl:text-4xl`}>{item.leftTitle}</h2>
                        <div className='mt-4 2xl:mt-5'>
                          {item.leftDesc.map((item) => (
                            <div className='flex items-start mt-3 2xl:mt-5'>
                              <img src={handIcon} alt="" />
                              <p className={`${theme === "dark" ? "text-white" : "text-[#333333]"} ms-2 font-poppins text-lg 2xl:text-2xl`}>{item.para}</p>
                            </div>
                          ))}
                        </div>
                      </div>

                      {/* {theme === "dark" ?
                        <img src={item.rightImgDark} className='ml-auto' alt="" /> :
                        <img src={item.rightImgLight} className='ml-auto' alt="" />
                      } */}
                      <Lottie options={{ loop: true, autoplay: true, animationData: item.rightImg }} className="h-full w-full" />
                    </div>
                  ))}

                </div>
              ) : ""}
            </div>
          </div>



          {/* left side features */}
          {showSideBar && (
            <div className={`animate-slideInSlow hidden lg:block lg:w-[210px] fixed top-[100px] 2xl:top-28 right-0 h-full  ${showSideBar ? "" : ""}`}>
              <div className=''>
                <div className={`rounded ${theme === "dark" ? "bg-white" : "bg-[#FDE6E4]"} py-2 px-2 flex flex-col gap-2 2xl:gap-3  font-poppins `}>
                  <h2 className={`font-bold text-xl mx-auto font-poppins ${theme === "dark" ? "text-primaryColor" : ""}`}>Features</h2>
                  {FeaturesListData.map((item) => (
                    <div
                      key={item.id}
                      ref={item.id === id ? featureRef : null}
                      onClick={() => handleFeatureClick(item)}
                      className={`${theme === "dark" ? "hover:bg-[#c74132d4]  hover:text-white" : "hover:bg-[#13224dd8] hover:text-white"} group ${item.id === selectedFeature.id ? `${theme === "dark" ? "bg-primaryColor hover:bg-primaryColor  text-white" : "bg-[#13224d] text-white"}` : "bg-white text-black"}  flex items-center rounded-md py-2 px-2 p-4 min-w-full cursor-pointer`}>
                      <img src={item.icon} className={`group-hover:filter group-hover:invert w-5 ${item.id === id ? "invert" : ""}`} alt="" />
                      <p className={`text-sm 4xl:text-lg ms-2 font-poppins`}>{item.title}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

        </div>
      </div>
    </>
  )
}

export default FeaturesPageSingle