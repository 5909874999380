import React from 'react'
import BannerSection from './Containers/Home/BannerSection'
import HowItWorks from './Containers/Home/HowItWorks'
import WhyChooseUs from './Containers/Home/whychoose'
import ChitAd from './Components/advertisement/ChitAd'
import Features from './Containers/Home/features'

const App = ({theme}) => {

  return (
    <div>
      <BannerSection theme={theme}/>
      <Features theme={theme} />
      <HowItWorks theme={theme}/>
      <WhyChooseUs theme={theme}/>
      <ChitAd theme={theme} />
    </div>
  )
}

export default App