import { Modal } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form';
import { companyRegister, getProfile } from '../../api/companyCreation';
import toast from 'react-hot-toast';
import { FeaturesListData } from '../../Data/FeaturesListData'
import cardTopLeft from "../../assets/Images/FormModal/CARD LEFT TOP.png"
import cardBottomRight from "../../assets/Images/FormModal/card bottom right.png"
import bg from "../../assets/Images/FormModal/bg3.svg"
import "./FormModal.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import SuccessModalApproval from '../SuccessModalApproval/SuccessModalApproval';
import { useQuery } from 'react-query';


const FormModal = ({ isModal, toggle = () => { }, isOpenForm, toggleForm = () => { }, mobile }) => {



    const [openSuccess, setOpenSuccess] = useState(false)

    const toggleSuccess = () => {
        setOpenSuccess(!openSuccess)
    }

    const schema = yup.object().shape({
        name: yup.string().required('This field is required. Please enter a value'),
        companyName: yup.string().required('This field is required. Please enter a value'),
        companyEmail: yup.string().optional('Email is required').email('Email is invalid'),
        companyMobile: yup.string()
            .required('This field is required.Please enter a value')
            .matches(/^\d{10}$/, 'Mobile number should be exactly 10 digits'),

        employees: yup.string().required('This field is required. Please enter a value'),

    });

    const methods = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
    });

    const { handleSubmit, register, setValue, formState: { errors } } = methods;

    const onSubmit = async (data) => {
        console.log(data, "data.......")
        const payload = {
            companyName: data.companyName,
            email: data.companyEmail,
            mobile: data.companyMobile,
            noOfEmployees: Number(data.employees),
            name: data.name,
        }

        console.log(payload)
        const updateApi = await companyRegister(payload)
        if (updateApi) {
            toast.success("Company Register Form Submitted Successfully...")
            console.log("Company Registered Successfully");
            // toggle()
            toggleForm()
            setOpenSuccess(true)
        }
    }

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        centerMode: false,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 908,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                },
            },
        ],

    };

    useEffect(() => {
        if (mobile) {
            setValue('companyMobile', mobile)
        }
    }, [mobile])


    return (
        <>
            <Modal size='2xl' className='hide-scrollbar overflow-y-scroll font-poppins' show={isOpenForm || isModal} onClose={toggle || toggleForm} >
                <FormProvider {...methods}>
                    <Modal.Body>
                        <div className='grid grid-cols-12 gap-x-4'>

                            <div className='col-span-6 bg-[#f6f6f6] border-[1px] border-[#dfdfdf] relative rounded-md w-full mx-auto flex flex-col justify-between gap-10 px-5 py-4 overflow-hidden'>
                                <img src={bg} className='absolute top-0 left-0' alt="" />

                                <div className='z-50'>
                                    <div className=''>
                                        <h2 className="text-primaryColor text-2xl font-bold">Welcome To CHITBID</h2>
                                        <p className='text-md mt-4'>Welcome To Join Chit Your Trustable Platform.</p>
                                    </div>

                                </div>

                                <div className='bg-[#642019] rounded-lg p-3 py-10 w-full relative mx-auto z-50 mb-10'>
                                    <img className='h-16 absolute top-0 left-0' src={cardTopLeft} alt="icon" />
                                    <img className='h-16 absolute bottom-0 right-0' src={cardBottomRight} alt="icon" />
                                    <div>
                                        <Slider {...settings} className='custom-dots'>

                                            {FeaturesListData.slice(0, 5).map((item, i) => (
                                                <div key={i}>
                                                    <div className='w-full text-center flex flex-col items-center justify-center relative'>
                                                        <img className='h-14' src={item.color_icon} alt="icon" />
                                                        <h1 className='text-sm font-poppins font-bold text-white mt-3 mb-1'>{item.title}</h1>
                                                        <p className='text-xs font-poppins text-[#e3e5edf4]'>{item.para ? item.para.slice(0, 120) + "..." : ""}</p>

                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                </div>


                            </div>

                            <div className='col-span-6'>
                                {/* {JSON.stringify(errors)} */}

                                <form
                                    onSubmit={handleSubmit(onSubmit)}
                                    className=' h-full'
                                >
                                    <div>
                                        <h2 className='mb-3 font-bold text-xl'>Register New Company</h2>

                                        <div className='col-span-12 '>
                                            <div className='font-medium text-sm'>
                                                Name<span className='text-danger-500 ms-1'>*</span>
                                            </div>
                                            <div className="mt-1">
                                                <input
                                                    id='name'
                                                    type="text"
                                                    placeholder='Enter your name'
                                                    className='bg-gray-100 border-[1px] border-gray-300 rounded-md w-full placeholder:text-xs'
                                                    {...register('name')}
                                                />
                                                {errors.name && <p className='text-red-500 text-xs mt-1 font-medium'>{errors.name.message}</p>}
                                            </div>
                                        </div>
                                        <div className='col-span-12 mt-1 '>
                                            <div className='font-medium text-sm'>
                                                Mobile<span className='text-danger-500 ms-1'>*</span>
                                            </div>
                                            <div className="mt-1">
                                                <div className='relative'>
                                                    <input
                                                        id='companyMobile'
                                                        type="number"

                                                        placeholder='Enter your Mobile'
                                                        className='bg-gray-100 border-[1px] border-gray-300 rounded-md w-full placeholder:text-xs'
                                                        {...register('companyMobile')}
                                                        readOnly
                                                    />

                                                </div>
                                                {errors.companyMobile && <p className='text-red-500 text-xs mt-1 font-medium'>{errors.companyMobile.message}</p>}
                                            </div>
                                        </div>
                                        <div className='col-span-12 mt-1'>
                                            <div className='font-medium text-sm'>
                                                Email<span className='text-danger-500 ms-1'>*</span>
                                            </div>
                                            <div className="mt-1">
                                                <input
                                                    id='companyEmail'
                                                    type="text"
                                                    placeholder='Enter your Email'
                                                    className='bg-gray-100 border-[1px] border-gray-300 rounded-md w-full placeholder:text-xs'
                                                    {...register('companyEmail')}
                                                />
                                                {errors.companyEmail && <p className='text-red-500 text-xs mt-1 font-medium'>{errors.companyEmail.message}</p>}
                                            </div>
                                        </div>
                                        <div className='col-span-12 mt-1'>
                                            <div className='font-medium text-sm'>
                                                Company Name<span className='text-danger-500 ms-1'>*</span>
                                            </div>
                                            <div className="mt-1">
                                                <input
                                                    id='companyName'
                                                    type="text"
                                                    placeholder='Enter your company name'
                                                    className='bg-gray-100 border-[1px] border-gray-300 rounded-md w-full placeholder:text-xs'
                                                    {...register('companyName')}
                                                />
                                                {errors.companyName && <p className='text-red-500 text-xs mt-1 font-medium'>{errors.companyName.message}</p>}
                                            </div>
                                        </div>



                                        <div className='col-span-12 mt-1 '>
                                            <div className='font-medium text-sm'>
                                                No.of.employees<span className='text-danger-500 ms-1'>*</span>
                                            </div>
                                            <div className="mt-1">
                                                <input
                                                    id='employees'
                                                    type="text"
                                                    placeholder='Enter No.of.employees'
                                                    className='bg-gray-100 border-[1px] border-gray-300 rounded-md w-full placeholder:text-xs'
                                                    {...register('employees')}
                                                />
                                                {errors.employees && <p className='text-red-500 text-xs mt-1 font-medium'>{errors.employees.message}</p>}
                                            </div>
                                        </div>
                                        <div className='mt-4 '>
                                            <button type='submit' className='bg-[#642019] px-5 py-2 text-md rounded-lg text-white'>Submit</button>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </Modal.Body>
                </FormProvider>
            </Modal>

            <SuccessModalApproval openSuccess={openSuccess} toggleSuccess={toggleSuccess} />
        </>
    )
}

export default FormModal