import React, { useState } from 'react';
import scan from '../../assets/Images/adv/scan.png';
import playstore from '../../assets/Images/adv/playStore.png';
import arrow from '../../assets/Images/adv/arrow_appstore.png';
import arrowImg from '../../assets/Images/BannerSection/arrow.png';
import handmobile from '../../assets/Images/adv/mobileHandImg.png';
import { Button } from 'flowbite-react';
import toast from 'react-hot-toast';
import { mobileOTPSignup } from '../../api/auth'
import OtpVerifyVisitor from '../OtpModalVisitor/OtpVerifyVisitor';

import handAnimation from "../../Json/Home/JoinOurCompany/Hand Mobile.json"
import Lottie from 'react-lottie';

const ChitAd = ({theme}) => {

    const [mobile, setMobile] = useState();
    const [verifyOtp, setVerifyOtp] = useState(false)

    const toggleFormChitAd = ()=>{
        setVerifyOtp(!verifyOtp)
    }

    console.log(mobile);
    const mobileSend = async () => {
        console.log("clicked------------------");
        if (mobile && mobile.length === 10) {
            const data = {
                mobile: mobile,
                visitor : true
            };
            const mobileStatus = await mobileOTPSignup(data);
            if (mobileStatus) {
                toast.success('OTP Send Successfully!');
                setVerifyOtp(true)
            }
        } else {
            toast.error("Mobile Number Must be 10 digit!")
        }
    }



    return (
        <>
        <div className={` ${theme === "dark" ? "bg-appAdBg " : "bg-white"} px-4   lg:px-[8%] py-5 md:py-7`}>
            <div className={`grid grid-cols-12 p-2 ${theme === "dark" ? "bg-white" : "bg-[#FDE6E4]"}  rounded-lg md:p-4 `}>

            <div className='flex justify-between col-span-12 overflow-hidden text-3xl md:col-span-6 lg:col-span-6 rounded-xl bg-primaryColor font-poppins'>
               <div className='relative px-2 py-4 md:px-4'>
                <h2 className='mb-1 font-bold text-white md:mb-2 lg:text-4xl'>CHITBID</h2>
                <p className='text-sm text-white md:text-lg'>One Solution for All Your</p>
                <p className='text-sm text-white md:text-lg'>Cross-Border Payments</p>
                <div className='mt-3'>
                    <img src={scan} className='w-[140px]' alt="" />
                    <p className='text-xs text-white'>scan to download this app</p>
                </div>
                    <img src={playstore} className='w-[138px] mt-4 h-auto' alt="" />
                <img src={arrow} className='absolute md:hidden lg:block bottom-0 right-[-20px] xl:right-[-20px] 2xl:right-[-10px] w-10 sm:w-14 xl:w-14 2xl:w-20' alt="" />
               </div>
               <div className='w-[40%] md:w-[55%] 2xl:w-[45%]'>
               <Lottie options={{loop: true, autoplay: true, animationData: handAnimation}}  className="h-full w-full ml-auto"/>
               </div>
            </div>

            <div className='flex flex-col justify-center col-span-12 mt-4 md:mt-0 md:ml-5 lg:py-2 md:col-span-6 lg:col-span-6 font-poppins lg:ml-9'>
            <h2 className='mb-1 text-2xl font-bold md:mb-2 lg:text-4xl'>Join Our Company</h2>
            <p className='text-[#333333] text-lg lg:text-xl mb-4 lg:mb-5'>Get start to join our company for the latest updates and financial tips.</p>
            <div className={`relative  w-full sm:w-[90%] xl:w-[78%] `}>
                  <input  type="number" onChange={(e) => setMobile(e.target.value)} className={`w-full placeholder:text-base  font-medium text-xl border-[1px] border-navydark rounded-xl  py-4 px-4 md:px-7  sm:px-4  `} placeholder="Enter Your Number" />
                  <button onClick={mobileSend} className={`absolute  z-10  flex items-center font-semibold text-white  bg-primaryColor rounded-lg  overflow-hidden group   right-0 top-[50%] transform translate-x-[-5%] translate-y-[-50%]  text-base sm:text-lg py-[10px] px-4 sm:px-6 `}>Join Now </button>
                </div>
                <div className='w-full mt-4  lg:mt-8 sm:w-[90%] xl:w-[78%]  flex justify-between'>
                    <div>
                        <h2 className='text-xl sm:text-2xl lg:text-3xl font-bold text-[#333333]'>+50,000,000</h2>
                        <h3 className='text-lg sm:text-xl lg:text-2xl font-semibold text-[#333333]'>Raised Capital</h3>
                        <p className='text-xs sm:text-base text-[#333333]'>Creating the successful path</p>
                    </div>
                    <div>
                        <h2 className='text-xl sm:text-2xl lg:text-3xl font-bold text-[#333333]'>+50,000,000</h2>
                        <h3 className='text-lg sm:text-xl lg:text-2xl font-semibold text-[#333333]'>Real Customer</h3>
                        <p className='text-xs sm:text-base text-[#333333]'>In the last 2 years</p>
                    </div>
                </div>
            </div>

            </div>
        </div>

        <OtpVerifyVisitor verifyOtp={verifyOtp} toggleFormChitAd={toggleFormChitAd} mobile={mobile}/>
        </>
    );
};

export default ChitAd;
