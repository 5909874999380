import { Button } from 'flowbite-react'
import React from 'react'
import aboutRightImg from "../../Json/FeaturesSinglePage/AboutUs/About us Banner.json"
import aboutFirstImg from "../../assets/Images/AboutUs/about_first_img.png"
import benefitsLightIcon from "../../assets/Images/AboutUs/benefits_chitbid_icon_light.svg"
import benefitsDarkIcon from "../../assets/Images/AboutUs/benefits_chitbid_icon_dark.svg"
import historyLightIcon from "../../assets/Images/AboutUs/history_chitbid_icon_light.svg"
import historyDarkIcon from "../../assets/Images/AboutUs/history_chitbid_icon_dark.svg"
import handIcon from "../../assets/Images/AboutUs/hand_icon.svg"

import auction_icon from '../../assets/Images/FeaturesListData/auction_step_icon.svg';
import ekyc_icon from '../../assets/Images/OurFeaturesData/ekyc_icon.svg';
import cm_icon from "../../assets/Images/OurFeaturesData/collection_icon.svg"
import crm_icon from "../../assets/Images/OurFeaturesData/crm_icon.svg"
import ps_icon from "../../assets/Images/OurFeaturesData/ps_icon.svg"
import risk_icon from "../../assets/Images/OurFeaturesData/riskmanage_icon.svg"

import arrow from "../../assets/Images/Logo/next_3114931 (1) 1.png"
import ChitAd from '../../Components/advertisement/ChitAd'
import { useNavigate } from 'react-router-dom'
import Lottie from 'react-lottie'

const AboutUs = ({ theme }) => {

    const navigate = useNavigate()

    const BenfitsData = [
        {
            img: handIcon,
            para: "Provides members with real-time access to their contribution status, auction results, and payout schedules. This transparency fosters trust among members by ensuring they are always informed about their financial interactions within the chit fund"
        },
        {
            img: handIcon,
            para: "Engage in our bid auctions to access lump sum funds and boost your financial flexibility."
        },
        {
            img: handIcon,
            para: "Provides comprehensive reports and analytics on fund performance, member contributions, auction results, and financial health"
        },
        {
            img: handIcon,
            para: "Provides a centralized platform for managing all aspects of chit fund operations, from member management to financial transactions, improving overall operational efficiency."
        },
    ]

    const featuresData = [
        {
            id: "liveAuction",
            title: "Live Auction",
            description: "Participate in our live auctions for a chance to win immediate payouts and maximize your savings",
            img: auction_icon,
            iconClass: "fas fa-gavel",
            iconColor: "text-red-500",
            link: "/Features",
        },
        {
            id: "e-kycVerifiaction",
            title: "E-KYC Verification",
            description: "Complete your KYC process conveniently with our streamlined Online KYC services",
            img: ekyc_icon,
            iconClass: "fas fa-comments",
            iconColor: "text-red-500",
            link: "/Features",
        },
        {
            id: "leadCRM",
            title: "Lead CRM",
            description: "Enhance your lead management with our powerful Lead CRM system",
            img: crm_icon,
            iconClass: "fas fa-file-alt",
            iconColor: "text-red-500",
            link: "/Features",
        },
        {
            id: "collectionManagement",
            title: "Collection Management",
            description: "Optimize your collections process with our comprehensive Collection Management solutions",
            img: cm_icon,
            iconClass: "fas fa-user-tie",
            iconColor: "text-blue-500",
            link: "/Features",
        },
        {
            id: "paymentSettlement",
            title: "Payment Settlement",
            description: "Streamline your financial transactions with our efficient Payment Settlement system",
            img: ps_icon,
            iconClass: "fas fa-comments",
            iconColor: "text-red-500",
            link: "/Features",
        },
        {
            id: "AICustomerDueReport",
            title: "AI for customer Due report",
            description: "Utilize AI-powered tools for accurate and timely customer due reports",
            img: risk_icon,
            iconClass: "fas fa-bullseye",
            iconColor: "text-red-500",
            link: "/Features",
        },
    ]




    return (
        <div>

            <div className={`grid grid-cols-12  pt-28  md:pt-24 ${theme === "dark" ? "bg-[#170603] bg-aboutUsTopBg" : "bg-lightBgTop bg-[#FDE6E4]"}   bg-center bg-cover px-[8%]  md:pb-0`}>

                <div className='col-span-12  md:col-span-7 justify-center flex flex-col'>
                    <h2 className={`text-xl md:text-2xl xl:text-3xl 2xl:text-4xl md:leading-10 font-semibold ${theme === "dark" ? "text-white" : "text-black"}  font-poppins max-w-[450px] xl:max-w-[590px]`}>About us</h2>
                    <p className={`text-sm xl:text-xl 2xl:text-2xl mt-3   ${theme === "dark" ? "text-[#E3E5ED]" : "text-[#333333]"}  font-poppins max-w-[400px] xl:max-w-[500px] 2xl:max-w-[650px]`}>Empowering chit funds with cutting-edge technology for seamless, secure, and transparent management and participation, ensuring a better experience for all</p>

                </div>

                <div className='col-span-12 md:col-span-5 py-3 mx-auto'>
                    <Lottie options={{ loop: true, autoplay: true, animationData: aboutRightImg }} className="h-full w-full" />
                </div>

            </div>

            {/* Benefits Of Chitbid */}
            <div className={`${theme === "dark" ? "bg-aboutUsBg1 bg-[#170603]" : "bg-white"}  py-6 grid grid-cols-1 lg:grid-cols-2 px-[8%] gap-x-6`}>

                <div className='bg-[#FBD4CE] rounded-2xl px-5 py-3'>
                    <h2 className='text-xl font-poppins mb-2'>Explain the advantages of using your software for <span className='text-primaryColor font-semibold'>CHITBID</span></h2>
                    <img src={aboutFirstImg} alt="" />


                </div>

                <div className='mt-3 lg:mt-0'>
                    {theme === "dark" ?
                        <img src={benefitsDarkIcon} className='xl:w-48 2xl:w-64' alt="" />
                        : <img src={benefitsLightIcon} className='xl:w-48 2xl:w-64' alt="" />}

                    <div className='mt-5 2xl:mt-7'>
                        {BenfitsData.map((item) => (
                            <div className='flex items-start mt-3 2xl:mt-5'>
                                <img src={item.img} alt="" />
                                <p className={`${theme === "dark" ? "text-white" : "text-[#333333]"} ms-2 font-poppins text-lg 2xl:text-xl`}>{item.para}</p>
                            </div>
                        ))}
                    </div>
                </div>

            </div>

            {/* History Of Chitbid */}
            <div className={`${theme === "dark" ? "bg-aboutUsBg1 bg-[#170603]" : "bg-[#FDE6E4]"}  py-6 grid grid-cols-1 lg:grid-cols-2 px-[8%] gap-x-6`}>

                <div>
                    {theme === "dark" ?
                        <img src={historyDarkIcon} className='xl:w-48 2xl:w-64' alt="" />
                        : <img src={historyLightIcon} className='xl:w-48 2xl:w-64' alt="" />}

                    <h2 className={`capitalize ${theme === "dark" ? "text-white" : "text-black"}  text-2xl 2xl:text-3xl font-semibold font-poppins xl:leading-10 2xl:leading-[50px] mt-5 2xl:mt-7`}>Experience The Tradition of chit funds combined with the <span className={`${theme === "dark" ? "text-transparent bg-clip-text bg-gradient-to-r from-[#E26353] via-[#FBD4CE] to-[#903225]" : "text-primaryColor"}`}>Power of Modern Technology.</span></h2>

                    <div className='flex items-start mt-3 2xl:mt-5'>
                        <img src={handIcon} alt="" />
                        <p className={`${theme === "dark" ? "text-white" : "text-[#333333]"}  ms-2 font-poppins text-lg 2xl:text-xl`}>At CHITBID, we are dedicated to revolutionizing the way chit funds operate by leveraging the power of technology. Our cutting-edge SaaS platform offers a secure, transparent, and user-friendly solution for managing and participating in chit funds, ensuring a seamless experience for all users. Our mission is to bring efficiency and ease to innovative solutions.</p>
                    </div>


                </div>

                <div className={` ${theme === "dark" ? "bg-[#FBD4CE]" : "bg-white"} rounded-2xl px-5 py-3 mt-3 lg:mt-0`}>
                    <h2 className='text-xl font-poppins mb-2'>Explain the advantages of using your software for <span className='text-primaryColor font-semibold'>CHITBID</span></h2>
                    <img src={aboutFirstImg} alt="" />
                </div>

            </div>

            {/* GET IN TOUCH */}
            <div className={`px-[8%]  ${theme === "dark" ? "bg-bannerBg" : "bg-white"}   pt-8`}>
                <h2 className='mb-4 text-center'>
                    <span className={`text-2xl font-bold md:text-4xl capitalize ${theme === "dark" ? " text-transparent bg-clip-text bg-gradient-to-r from-[#E26353] via-[#FBD4CE] to-[#E26353]" : "text-black"}`}>get in to touch</span>
                </h2>
                <p className={`max-w-2xl mx-auto  text-center ${theme === "dark" ? "text-[#E3E5ED]" : "text-[#333333]"} `}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 mt-5">
                    {featuresData.map((feature, index) => (
                        <div key={index} className={`bg-[#FEFBFA] p-4 rounded-lg shadow-md text-center flex flex-col items-center justify-center 2xl:h-full ${theme === "dark" ? "" : "border-[1px] border-[#3333334f]"} transform transition-all duration-200 ease-in-out hover:text-white hover:bg-[#13224D] `}>
                            <div className="flex justify-center items-center mb-4">
                                <img src={feature.img} alt={feature.title} className="w-9" />
                                <h3 className="text-xl font-bold ms-3">{feature.title}</h3>
                            </div>
                            <p className="mb-4 2xl:text-xl">
                                {feature.description}
                            </p>
                            <div className='flex justify-center cursor-pointer group' onClick={() => navigate(`${feature.link}/${feature.id}`)}>
                                <a className="text-red-500 group-hover:text-white duration-100 ease-in-out">Learn More</a>
                                <img src={arrow} height={30} width={30} />
                            </div>
                        </div>
                    ))}
                </div>

                <div className="flex justify-center text-center font-poppins mt-3 cursor-pointer">
                    <a href="/Features" className="flex justify-center items-center text-[10px] md:text-sm lg:text-base px-1 lg:px-4 py-1 lg:py-2 transition duration-300 border-[0.5px] border-[#e3e5edb9] rounded-md  shadow-md">
                        <div className={`inline-flex items-center cursor-pointer ${theme === "dark" ? "text-white" : "text-[#333333] "}`}
                            onClick={() => navigate("/Features")}>
                            Get a complete overview of our <span className='text-transparent bg-clip-text bg-gradient-to-r from-[#E26353] via-[#FBD4CE] to-[#903225] font-bold mx-1'>CHITBID</span>  Features
                            <img src={arrow} className='w-8 h-8' alt="" />
                        </div>
                    </a>
                </div>

            </div>

            {/* ad section */}
            <ChitAd theme={theme} />

        </div>
    )
}

export default AboutUs