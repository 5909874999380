import React from 'react'
import vector from "../../assets/Images/ContactUs/contact_vector.svg"
import { IoCall, IoLocation, IoMail } from 'react-icons/io5'
import { Button } from 'flowbite-react'

const ContactUs = ({theme}) => {
  return (
    <div className={`pt-24 px-[8%] pb-4 ${theme === "dark" ? "bg-contactUsBgDark" : "bg-contactUsBgLight bg-center bg-cover"}`}>

        <div className='grid grid-cols-12 gap-x-6'>

        <div className='col-span-12 lg:col-span-7 flex flex-col justify-between font-poppins'>
            <div className='mt-3'>
            <h2 className={`${theme === "dark" ? "text-white" : ""} xl:text-lg`}>How can we <span className={` ${theme === "dark" ? "text-transparent bg-clip-text bg-gradient-to-r from-[#E26353] via-[#FBD4CE] to-[#903225]" : "text-primaryColor"}`}>Help You?</span></h2>
            <p className={`${theme === "dark" ? "text-[#E3E5ED]" : "text-[#333333] "} xl:text-lg mt-2`}>Questions, interest in our software or feedback—we’re here to help.</p>
                </div>

            <div className='bg-[#EDF1F6] rounded-md p-5 font-poppins mt-2 md:mt-5 lg:mt-8 xl:mt-0'>
                <h2 className='font-bold text-2xl 2xl:text-3xl'>Contact Us</h2>
                <p className='text-[#666666] mt-2 2xl:text-lg'>"Reach out to ChitBid for seamless chit fund management and exceptional customer support."</p>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-x-5 mt-4'>
                    <div>
                        <h2 className='2xl:text-lg'>First Name</h2>
                        <input type="text" placeholder='Enter Your First Name' className='rounded-md w-full border-[#D0D5DD] mt-1'/>
                    </div>
                    <div>
                        <h2 className='2xl:text-lg'>Second Name</h2>
                        <input type="text" placeholder='Enter Your Second Name' className='rounded-md w-full border-[#D0D5DD] mt-1'/>
                    </div>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-x-5 mt-3'>
                    <div>
                        <h2 className='2xl:text-lg'>Contact Number</h2>
                        <input type="tel" placeholder='Enter Your Contact Number' className='rounded-md w-full border-[#D0D5DD] mt-1'/>
                    </div>
                    <div>
                        <h2 className='2xl:text-lg'>E-Mail</h2>
                        <input type="email" placeholder='Enter Your E-mail' className='rounded-md w-full border-[#D0D5DD] mt-1'/>
                    </div>
                </div>
                <div className='mt-3'>
                    <h2 className='2xl:text-lg'>Your Message</h2>
                    <textarea placeholder='Type here....' className='w-full rounded-md border-[#D0D5DD] mt-1'></textarea>
                </div>
                <div className='flex md:items-center mt-4'>
                    <input type="checkbox" className='border-[#D0D5DD] checked:bg-primaryColor  checked:border-transparent focus:outline-none rounded' />
                    <p className='ms-2 text-[#666666]'>By submitting this form, you agree to the <span className='text-primaryColor'>privacy policy.</span></p>
                </div>

                <Button  className='bg-primaryColor hover:bg-[#c74132e6] text-white w-full mt-5'>
                    Submit
                </Button>
            </div>
        </div>

        <div className='col-span-12 lg:col-span-5'>
                <img src={vector} className='w-full md:w-[70%] lg:w-[83%] 2xl:w-[75%] object-cover mx-auto' alt="" />
                <div className={`${theme === "dark" ? "bg-[#EDF1F6]" : "bg-[#EDF1F6]"} rounded-md py-5 px-5 font-poppins`}>
                    <h2 className='text-2xl font-bold'>Customer Support</h2>
                    <p className='text-[#333333] mt-2'>Contact our award-winning support team</p>
                    <div className='flex items-center mt-3'>
                        <IoCall className='text-primaryColor ' size={27} />
                        <a href="tel" className='ms-2'>+91 8826555176</a>
                    </div>
                    <div className='flex items-center mt-3'>
                    <IoMail className='text-primaryColor ' size={26} />
                        <a href="tel" className='ms-2'>hello@rdash.io</a>
                    </div>
                    <div className='flex  mt-3'>
                    <IoLocation className='text-primaryColor ' size={28} />
                        <p className='ms-2'>81, Sector 44 Rd, Sector 44,
                        Gurugram, Haryana 122003</p>
                    </div>
                </div>
        </div>

        </div>

    </div>
  )
}

export default ContactUs