import { Button, Modal } from 'flowbite-react';
import { useState } from 'react';
import OtpInput from 'react-otp-input';
import './OtpModal.css'
import { useNavigate } from 'react-router-dom';
import { mobileOTPSignup, mobileOTPSignUpVerify } from '../../api/auth';
import toast from 'react-hot-toast';

function OtpModal({ isModal, toggle, mobile }) {
    const [otp, setOtp] = useState();
    const navigate = useNavigate()

    const otpTrigger = async () => {
        const data = {
            mobile: mobile,
        };
        if (mobile && mobile.length === 10) {
            const mobileStatus = await mobileOTPSignup(data);
            if (mobileStatus) {
                toast.success('OTP Resend Successfully!');
            }
        }
    }

    const otpVerify = async () => {
        const payload = {
            mobile: mobile,
            otp
        }
        const updateApi = await mobileOTPSignUpVerify(payload)
        if (updateApi) {
            toast.success('OTP Verified Successfully')
            const data = updateApi.data.result;
            localStorage.setItem('access-token', data.tokens.accessToken);
            localStorage.setItem('role', btoa(data.user.role.name));
            localStorage.setItem('refreshToken', data.tokens.refreshToken);
            localStorage.setItem('userId', data.user._id);
            localStorage.setItem('mobile', mobile);
            setOtp('')
            toggle()
        }
    }

    return (
        <>
            <Modal className='z-[2000]' show={isModal} style={{ width: 'auto' }} onClose={toggle} popup>
                <Modal.Body>
                    <div className="text-center p-6">
                        <h1 className='text-2xl mb-3 font-bold'>OTP Verification</h1>
                        <h3 className="mb-5 text-md font-normal text-gray-500 dark:text-gray-400">
                            Enter the OTP you received at <span className='text-navydark font-bold'>+91 {mobile}</span>
                        </h3>
                        <div className='flex justify-center'>
                            <OtpInput
                                value={otp}
                                onChange={(e) => setOtp(e)}
                                inputType="tel"
                                numInputs={6}
                                inputStyle="custom-otp-field"
                                renderInput={(props) => <input {...props} />}
                            />

                        </div>
                        <div className="flex justify-center mt-5">
                            <Button color="dark" size='sm' className='bg-primaryColor' disabled={!otp || otp.length !== 6} onClick={otpVerify}>
                                Verify
                            </Button>
                        </div>
                        <p className='text-sm mt-3 text-gray-500'>Didn't receive code? <span className='text-teal-600 underline cursor-pointer' onClick={otpTrigger}>Request again</span></p>
                    </div>
                </Modal.Body>
            </Modal >
        </>
    );
}

export default OtpModal