export const baseUrl = "https://devapi.chitbid.com"

const siteUrls = {
    auth: {
        mobileSignup: `${baseUrl}/otp/send`,
        mobileSignupVerify: `${baseUrl}/otp/verify`,
        profile: `${baseUrl}/profile`
    },
    time: {
        index: `${baseUrl}/auth/demo-slot?date=`,
    },
    demo: {
        post: `${baseUrl}/book-a-demo`
    },
    companyRegister: {
        post: `${baseUrl}/companyRegister`
    },
    imageUpload: {
        singleUpload: {
            post: `${baseUrl}/auth/upload`
        }
    },

}

export default siteUrls;        