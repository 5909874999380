import axios from "axios";
import siteUrls from "../api-config/ApiConfig";
import toast from "react-hot-toast";

export const companyRegister = async (payload) => {
    try {
        const companyRegister = await axios.post(siteUrls.companyRegister.post, payload);
        return companyRegister;
    } catch (error) {
        console.error('Error in Company Register', error);
        toast.error(error.response.data.msg)
    }
};

export const imageUpload = async (payload) => {
    try {
        const imageUpload = await axios.post(siteUrls.imageUpload.singleUpload.post, payload);
        return imageUpload;
    } catch (error) {
        console.error('Error in Upload', error);
        toast.error(error.response.data.msg)
    }
};

export const getProfile = async () => {
    const response = await axios.get(`${siteUrls.auth.profile}`);
    return response
};