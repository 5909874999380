import React, { useState } from 'react';
import Lottie from 'react-lottie';
// import vedio1 from "../../../assets/Images/HowItWorks/R.mp4"
import img3 from "../../assets/Images/HowItWorks/img3.png"
import img4 from "../../assets/Images/HowItWorks/img4.png"
import img5 from "../../assets/Images/HowItWorks/img5.png"
import icon1 from "../../assets/Images/Logo/col.png"
import icon2 from "../../assets/Images/HowItWorks/carete_chit.png"
import icon3 from "../../assets/Images/HowItWorks/auction.png"
import icon4 from "../../assets/Images/HowItWorks/payment_icon.svg"
import icon5 from "../../assets/Images/HowItWorks/payment_settlement.svg"

// json files
import animation_json1 from "../../Json/Home/HowItWorks/Register1922x1242.json"
import animation_json2 from "../../Json/Home/HowItWorks/Create Chit1922x1242.json"
import animation_json3 from "../../Json/Home/HowItWorks/live auction.json"
import animation_json4 from "../../Json/Home/HowItWorks/Payment Collection.json"
import animation_json5 from "../../Json/Home/HowItWorks/Payment Settlement.json"

const HowItWorks = ({theme}) => {


    const data = [
        {
            index: 1,
            heading: "Register",
            para: "Register now to join our chit fund and start growing your savings today!",
            img: animation_json1,
            icon : icon1
        },
        {
            index: 2,
            heading: "Create Chits",
            para: "Create chits to start saving and earning through our flexible and rewarding chit fund plans!",
            img: animation_json2,
            icon : icon2
        },
        {
            index: 3,
            heading: "Live Auction",
            para: "Engage in live auctions to bid and win lump sum payouts in real-time!",
            img: animation_json3,
            icon : icon3
        },
        {
            index: 4,
            heading: "Payment Collection",
            para: "Ensure timely and efficient payments with our streamlined Payment Collection system",
            img: animation_json4,
            icon : icon4
        },
        {
            index: 5,
            heading: "Payment Settlement",
            para: "Simplify and expedite financial transactions with our Payment Settlement service",
            img: animation_json5,
            icon : icon5
        }
    ];

    const [selectedIndex, setSelectedIndex] = useState(data[0].index);

    const handleItemClick = (index) => {
        setSelectedIndex(index);
    };

    const selectedItem = data.find(item => item.index === selectedIndex);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: selectedItem.img,
      };

    return (
        <div className={`h-full py-5 font-poppins ${theme === "dark" ? "bg-howItWorksBg " : "bg-[#FDE6E4] "} `}>
            <div className=" px-4 mx-auto md:px-10 lg:px-[8%]">
                <h2 className={`mb-4 text-3xl font-bold text-center ${theme === "dark" ? "text-white" : "text-black"}`}>How It Works</h2>
                <p className={`mx-auto text-center mb-7 max-w-6xl ${theme === "dark" ? "text-white" : "text-[#333333]"}`}>CHITBID streamlines chit fund management with easy member onboarding, secure auctions, efficient payments, and robust risk assessment, ensuring transparency and compliance.</p>
                <div className="grid grid-cols-12 gap-4 lg:h-full">
                    <div className="col-span-12 space-y-2 xl:space-y-3 2xl:space-y-4 lg:col-span-5 xl:col-span-4">
                        {data.map((item) => (
                            <div
                                key={item.index}
                                className={`
                                   p-3 rounded group duration-300 transition-all ease-out cursor-pointer 
                                    ${selectedIndex === item.index 
                                    ? `${theme === "dark" ? 'bg-white text-black' : 'bg-[#13224D] text-white'}` 
                                    : `${theme === "dark" ? "bg-[#4F4745]" : "bg-[#3333333a]"}`} 
                                    ${theme === "dark" ? "hover:bg-white" : "hover:bg-[#13224D]"}
                                    `}
                                onClick={() => handleItemClick(item.index)}
                            >
                                <div className='flex justify-between'>
                                <h3 className={`text-xl font-semibold ${selectedIndex === item.index ? `${theme === "dark" ? "text-black " : "text-white group-hover:text-white"}`  : 'group-hover:text-black'}  ${theme === "dark" ? "" : "group-hover:text-white"}`}>{item.heading}</h3>
                                <img src={item.icon} className='w-7 h-7' alt="" />
                                </div>
                                <p className={`text-sm mt-1 ${selectedIndex === item.index ?  `${theme === "dark" ? "'text-gray-700' " : "text-white"}` : 'group-hover:text-gray-700'} ${theme === "dark" ? "" : "group-hover:text-white"}`}>{item.para}</p>
                            </div>
                        ))}
                    </div>
                    <div className=" col-span-12 lg:col-span-7 xl:col-span-8 w-full">
                    {/* <video src={vedio1} autoPlay={true} controls={false} muted loop={true} className=''></video> */}
                        {/* <img src={selectedItem.img} alt="How it works" className="w-full h-full" /> */}
                        <Lottie options={defaultOptions} className="w-full" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowItWorks;
