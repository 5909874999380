import React, { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { FaArrowLeft, FaArrowRight, FaChevronLeft } from 'react-icons/fa'
import OTPInput from 'react-otp-input'
import { Link, useLocation } from 'react-router-dom'
import { mobileOTPSignup, mobileOTPSignUpVerify } from '../../api/auth'
import { IoMdArrowDropleft, IoMdArrowDropright } from 'react-icons/io'
import { Button } from 'flowbite-react'
import FormModal from '../FormModal/FormModal'
import "./Drawer.css"

const Drawer = ({ theme }) => {

    const [isDrawer, setIsDrawer] = useState(false);
    const [isVerify, setIsVerify] = useState(false);
    const [mobile, setMobile] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [otp, setOtp] = useState();
    const mobileInputRef = useRef(null);
    const otpInputRef = useRef(null);
    const location = useLocation();

    const toggleModal = () => {
        setOpenModal(!openModal)
    }



    const mobileSend = async () => {
        if (mobile) {
            const data = {
                mobile: mobile,
                visitor: true
            };
            const mobileStatus = await mobileOTPSignup(data);
            if (mobileStatus) {
                toast.success('OTP Send Successfully!');
                setIsVerify(true)
            }
        } else {
            toast.error("Mobile Number Must be 10 digit!")
        }
    }


    const otpVerify = async () => {

        const payload = {
            mobile: mobile,
            otp
        }
        const updateApi = await mobileOTPSignUpVerify(payload)
        if (updateApi) {
            toast.success('OTP Verified Successfully')
            const data = updateApi.data.result;
            localStorage.setItem('access-token', data.tokens.accessToken);
            localStorage.setItem('role', btoa(data.user.role.name));
            localStorage.setItem('refreshToken', data.tokens.refreshToken);
            localStorage.setItem('userId', data.user._id);
            localStorage.setItem('mobile', mobile);
            setMobile('')
            setOtp('')
            setIsVerify(false)
            setIsDrawer(false)
            setOpenModal(true)
        }
    }


    useEffect(() => {
        if (isDrawer && mobileInputRef.current) {
            mobileInputRef.current.focus();
        }
    }, [isDrawer]);

    useEffect(() => {
        if (isVerify) {
            const timer = setTimeout(() => {
                const otpInputElements = document.querySelectorAll('.custom-otp-field2');
                if (otpInputElements.length > 0) {
                    otpInputElements[0].focus();
                }
            }, 200); // Small delay to ensure input is in the DOM

            return () => clearTimeout(timer);
        }
    }, [isVerify]);



    return (
        <>
            <div className={`fixed right-0 top-[20%] z-50 transition-transform duration-500  font-poppins ${isDrawer ? 'translate-x-0' : 'translate-x-[295px]'}`}>

                <div className={`w-[330px]  h-[240px] flex flex-col justify-center items-center relative px-5 py-3  border-[1px] border-dotted  shadow-xl border-r-0 rounded-l-lg ${theme === "dark" ? "border-white bg-black/70 backdrop-blur-lg" : "border-slate-400 bg-white/80 backdrop-blur-lg"}`}>
                    {!isDrawer && (
                        <div className={`flex flex-col absolute top-[50%] left-[15px] transform translate-y-[-50%] font-bold ${theme === "dark" ? "text-white" : "text-[#13224d]"}`}>
                            <p className='text-xs'>G</p>
                            <p className='text-xs'>E</p>
                            <p className='text-xs mb-1'>T</p>
                            <p className='text-xs'>S</p>
                            <p className='text-xs'>T</p>
                            <p className='text-xs'>A</p>
                            <p className='text-xs'>R</p>
                            <p className='text-xs mb-1'>T</p>
                            <p className='text-xs '>F</p>
                            <p className='text-xs'>R</p>
                            <p className='text-xs'>E</p>
                            <p className='text-xs'>E</p>
                        </div>
                    )}

                    <div className={`${!isDrawer ? "hidden" : "block"}`}>
                        <h2 className={`${theme === "dark" ? "text-white" : "text-[#13224d] "} text-xl font-bold`}>GET START FREE</h2>
                        <div>
                            <p className={`text-xs font-medium ${theme === "dark" ? "text-[#E3E5ED]" : ""} pt-2`}>From lead CRM and proposal management to inventory and vendor management, our platform offers everything you need to ensure your projects run smoothly and efficiently.</p>
                            {isVerify ? (
                                <div className='flex justify-center mt-3'>
                                    <OTPInput
                                        value={otp}
                                        onChange={(e) => setOtp(e)}
                                        inputType="tel"
                                        numInputs={6}
                                        inputStyle="custom-otp-field2"
                                        renderInput={(props) => <input  {...props} />}
                                    />
                                </div>
                            ) : (
                                <input ref={mobileInputRef} onChange={(e) => setMobile(e.target.value)} className='custom-number-input mt-5 border-[1px] border-slate-400 border-dotted w-full rounded-lg' type="number" placeholder='Enter Your Mobile' />
                            )}
                            <div className='flex justify-between items-center mt-3'>
                                <Link to={'/schedule-demo'}>
                                    <span onClick={() => setIsDrawer(false)} className={`text-sm underline  cursor-pointer ${theme === "dark" ? "text-[#E3E5ED] hover:text-white" : ""}`}>Want Demo ?</span>
                                </Link>
                                {isVerify ? (
                                    <div className='flex items-center'>
                                        <span onClick={() => {
                                            setIsVerify(false)
                                            setOtp(null)
                                        }} className='me-2 cursor-pointer'><FaChevronLeft size={15} /></span>
                                        <Button className='bg-primaryColor' color="dark" size='sm' disabled={!otp || otp.length !== 6} onClick={otpVerify}>
                                            Verify OTP
                                        </Button>
                                    </div>
                                ) : (
                                    <button onClick={mobileSend} className='bg-primaryColor w-fit p-3 px-3 text-white text-xs rounded-md'>Start Now</button>
                                )}
                            </div>
                        </div>

                    </div>

                    <div onClick={() => setIsDrawer(!isDrawer)} className={` cursor-pointer h-9 w-9 absolute top-[50%] -left-7 transform translate-y-[-50%] rounded-md flex items-center justify-center ${theme === "dark" ? "bg-slate-500 hover:bg-slate-400" : "bg-[#13224d] hover:bg-[#13224de6]"}`}>
                        <span>{isDrawer ? <FaArrowRight size={20} className="text-white" /> : <span className='bounce-arrow'><FaArrowLeft size={20} className='text-white' /></span>}</span>

                    </div>

                </div>
            </div>

            <FormModal isModal={openModal} toggle={toggleModal} mobile={mobile} />

        </>
    )
}

export default Drawer