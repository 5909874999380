import React from 'react'
import Marquee from 'react-fast-marquee'
import { Link } from 'react-router-dom'
import { FeaturesListData } from '../../Data/FeaturesListData'
import feature_dark from "../../assets/Images/FeaturesBottom/feature_bottom_dark.svg"
import feature_light from "../../assets/Images/FeaturesBottom/feature_bottom_light.svg"

import "./FeatureBottom.css"
import { BsWhatsapp } from 'react-icons/bs'

const FeaturesBottom = ({theme}) => {

const handleWhatsappClick = ()=>{
    const mobile = "9500516721";
    const message = "hello, this is a sample msg";

    const whatsappUrl = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? `whatsapp://send?phone=${mobile}&text=${message}` : `https://web.whatsapp.com/send?phone=${mobile}&text=${message}`

    window.open(whatsappUrl,"_blank")
}

  return (
    <>
    <div className={`fixed bottom-0 left-0 right-0 w-full z-40 `}>
        
              <button
              onClick={handleWhatsappClick}
              class={`Btn ml-auto mb-3 mr-3  2xl:w-14 2xl:h-14 ${theme === "dark" ? "" : ""}`}>
                  <span class="svgContainer">
                      <BsWhatsapp size={20} className='w-9 text-white'/>
                  </span>
                  <span class="BG"></span>
              </button>


    <div className=' w-full relative border-t-[1px]  ${theme === "dark" ? "border-slate-300" : ""}'>
                <Marquee
                    className={`w-100 testing backdrop-blur-3xl  py-[6px] ${theme === "dark" ? "bg-white/50" : "bg-black/20"}`}
                    direction="right"
                    speed={50}
                    loop={0}
                    gradient={false}
                    pauseOnHover={true}
                >
                    {FeaturesListData.map((item, i) => (
                        <div key={i} className='w-fit'>
                            <Link to={`/Features/${item.id}`}>
                                <div className='py-[6px]  px-[6px] rounded-lg  bg-white shadow flex items-center  min-w-fit ms-2 cursor-pointer'>
                                    <img className='w-9 2xl:w-11' src={item.color_icon} alt="icon" />
                                    <h1 className=' ms-2 font-medium text-xs xl:text-sm'>{item.title}</h1>
                                </div>
                            </Link>
                        </div>
                    ))}
                </Marquee>
                {theme === "dark" ? (
                     <img className='absolute -rotate-[10deg] -top-[65%] md:-top-[100%] left-[3%] md:-left-[35%] lg:-left-[40%] 2xl:-top-[112%] xl:-left-[40%] z-50 h-14 md:h-20 2xl:h-[100px]  object-contain' src={feature_dark} alt="title" />
                ) : (
                    <img className='absolute -rotate-[10deg] -top-[65%] md:-top-[100%] left-[3%] md:-left-[35%] lg:-left-[40%] 2xl:-top-[112%] xl:-left-[40%] z-50 h-14 md:h-20 2xl:h-[100px]  object-contain' src={feature_light} alt="title" />
                )}
               
            </div>
            </div>

            </>
  )
}

export default FeaturesBottom