import { Button } from 'flowbite-react'
import React from 'react'
import arrow from "../../assets/Images/BannerSection/arrow.png"
import auction from '../../assets/Images/Logo/legal-advice_7355110 1.png';
import chat from '../../assets/Images/Logo/chat.png';
import pro from '../../assets/Images/Logo/pro.png';
import col from '../../assets/Images/Logo/col.png';
import cus from '../../assets/Images/Logo/customer-service_9759776 1.png';
import tar from "../../assets/Images/Logo/target-audience_11744835 1.png"
import vedio from "../../assets/Images/Features/gif.mp4"
import phoneBg from "../../assets/Images/Features/phoneBg.png"
import ChitAd from '../../Components/advertisement/ChitAd';
import { useNavigate } from 'react-router-dom';
import { FeaturesListData } from '../../Data/FeaturesListData';
import { BiArrowFromRight } from 'react-icons/bi';
import { GoArrowUpRight } from 'react-icons/go';

const FeaturesPage = ({theme}) => {

    const navigate = useNavigate();

    const featuresData = [
        {
            id : "liveAuction",
            title: "Live Auction",
            description: "Participate in our live auctions for a chance to win immediate payouts and maximize your savings",
            img: auction,
            iconClass: "fas fa-gavel",
            iconColor: "text-red-500",
            link: "/Features",
        },
        {
            id : "e-kycVerifiaction",
            title: "E-KYC Verification",
            description: "Complete your KYC process conveniently with our streamlined Online KYC services",
            img: chat,
            iconClass: "fas fa-comments",
            iconColor: "text-red-500",
            link: "/Features",
        },
        {
            id : "leadCRM",
            title: "Lead CRM",
            description: "Enhance your lead management with our powerful Lead CRM system",
            img: pro,
            iconClass: "fas fa-file-alt",
            iconColor: "text-red-500",
            link: "/Features",
        },
        {
            id : "collectionManagement",
            title: "Collection Management",
            description: "Optimize your collections process with our comprehensive Collection Management solutions",
            img: col,
            iconClass: "fas fa-user-tie",
            iconColor: "text-blue-500",
            link: "/Features",
        },
        {
            id : "paymentSettlement",
            title: "Payment Settlement",
            description: "Streamline your financial transactions with our efficient Payment Settlement system",
            img: cus,
            iconClass: "fas fa-comments",
            iconColor: "text-red-500",
            link: "/Features",
        },
        {
            id : "AICustomerDueReport",
            title: "AI for customer Due report",
            description: "Utilize AI-powered tools for accurate and timely customer due reports",
            img: tar,
            iconClass: "fas fa-bullseye",
            iconColor: "text-red-500",
            link: "/Features",
        },
        {
            id : "Chat&ChitForum",
            title: "chat & chit forum",
            description: "Streamline your financial transactions with our efficient Payment Settlement system",
            img: tar,
            iconClass: "fas fa-bullseye",
            iconColor: "text-red-500",
            link: "/Features",
        },
        {
            id : "ReportManagement",
            title: "Report management",
            description: "Utilize AI-powered tools for accurate and timely customer due reports",
            img: tar,
            iconClass: "fas fa-bullseye",
            iconColor: "text-red-500",
            link: "/Features",
        },
        {
            id : "DocumentVerification",
            title: "Document Verifiaction",
            description: "Utilize AI-powered tools for accurate and timely customer due reports",
            img: tar,
            iconClass: "fas fa-bullseye",
            iconColor: "text-red-500",
            link: "/Features",
        },
    ];

  return (
    <>

            <div className={`  pt-24 md:pt-28 ${theme === "dark" ? "bg-featuresTopBg " : "bg-lightBgTop bg-[#FDE6E4]"} h-full   flex flex-col justify-center w-full items-center px-[8%] pb-5 md:pb-0`}>

                    <h2 className={`text-xl md:text-2xl xl:text-3xl 2xl:text-4xl  font-semibold md:text-center ${theme === "dark" ? "text-white" : "text-black"} font-poppins  w-full md:max-w-[450px] xl:max-w-[650px] 2xl:max-w-[750px]`}>Experience The Thrill Of Live Auctions with our <span className={`${theme === "dark" ? " text-transparent bg-clip-text bg-gradient-to-r from-[#E26353] via-[#FBD4CE] to-[#903225]" : " text-primaryColor"} `}>Real-Time Bidding System</span> </h2>
                    <p className={`text-sm xl:text-lg 2xl:text-xl mt-3 mx-auto font-poppins ${theme === "dark" ? "text-[#E3E5ED]" : "text-[#333333]"} `}>Participate in auctions from any device, whether you're at home or on the go.</p>
                    <div className='flex items-center mt-3 lg:mt-5'>
                    <Button color="failure"  className='px-0 md:pt-1 h-11 md:h-12 bg-primaryColor me-1 lg:me-5 hover:bg-primaryColor'>Get Start For Free <img src={arrow} className='w-6 h-6 text-white' alt="" /></Button>
                    <Button  color="gray" className='h-10 p-0 md:pt-1 md:h-12'>Request a Demo</Button>
                    </div>
                   
                    <div>
                        <img src="" alt="" />
                        <img src="" alt="" />
                        <img src="" alt="" />
                        <div>
                            <img src="" alt="" />
                            <img src="" alt="" />
                        </div>
                    </div>
            </div>

                {/* features all list*/}
            <div className={` h-full ${theme === "dark" ? "bg-[#170603] bg-featuresListBg" : "bg-white" }  pt-5   px-[8%]`}>

                <div className="container mx-auto ">
                    <h2 className='mb-4 text-center'>
                        <span className={`text-2xl font-bold md:text-4xl ${theme === "dark" ? " text-transparent bg-clip-text bg-gradient-to-r from-[#E26353] via-[#FBD4CE] to-[#E26353]" : "text-black"}`}>Features</span>
                    </h2>
                    <p className={`max-w-2xl mx-auto mb-5 xl:mb-8 text-center ${theme === "dark" ? "text-[#E3E5ED]" : "text-[#333333]"} `}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-3  gap-5">
                            {FeaturesListData.map((feature, index) => (
                                <div key={index} className={`bg-white p-4 rounded-lg shadow-md text-center flex flex-col items-center justify-center 2xl:h-full ${theme === "dark" ? "" : "border-[1px] border-[#3333334f]"} transform transition-all duration-200 ease-in-out hover:text-white hover:bg-[#13224D] `}>
                                    <div className="flex justify-center items-center mb-4">
                                        <img src={feature.feature_icon} alt={feature.title}  className="w-9 2xl:w-10" />
                                        <h3 className="text-xl font-bold ms-2">{feature.title}</h3>
                                    </div>
                                    <p className="mb-4 2xl:text-xl">
                                        {feature.description}
                                    </p>
                                    <div className='flex justify-center hover:cursor-pointer group' 
                                onClick={()=>navigate(`${feature.id}`)}>
                                    <p className="text-red-500 flex items-center group-hover:text-white">Learn More <GoArrowUpRight className="mt-1 group-hover:text-white" size={20}/></p> 
                                </div>
                                </div>
                            ))}
                    

            
                    </div>

            
                </div>
            </div>

                {/* playstore ad */}
                <ChitAd theme={theme}/>
    </>
  )
}

export default FeaturesPage