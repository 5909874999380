import React, { useEffect, useRef, useState } from 'react'
import OtpVerifyVisitor from './OtpVerifyVisitor';
import { mobileOTPSignup } from '../../api/auth';
import toast from 'react-hot-toast';
import { Button, Modal } from 'flowbite-react';
import "../Drawer/Drawer.css"
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
const validationSchema = yup.object({
    mobile: yup.string()
        .required('This field is required. Please enter a value.')
        .matches(/^[6-9]\d{9}$/, 'Mobile number should start with 6 to 9.')
});
const OtpSendVisitor = ({ isOpenModal, toggle }) => {

    const [mobile, setMobile] = useState();
    const [openVerifyModal, setOpenVerifyModal] = useState(false);
    const mobileInputRef = useRef(null);
    const [error, setError] = useState('');
    const navigate = useNavigate()
    const handleMobileChange = (e) => {
        setMobile(e.target.value);
    };
    const toggleModal = () => {
        setOpenVerifyModal(!openVerifyModal)
    }

    useEffect(() => {
        if (isOpenModal && mobileInputRef.current) {
            mobileInputRef.current.focus();
        }
    }, [isOpenModal]);

    const mobileSend = async () => {
        try {
            // Validate mobile number
            await validationSchema.validate({ mobile });

            const data = {
                mobile: mobile,
                visitor: true
            };
            const mobileStatus = await mobileOTPSignup(data);
            if (mobileStatus) {
                toast.success('OTP Sent Successfully!');
                setOpenVerifyModal(true);
                toggle();
            }
        } catch (error) {
            if (error.name === 'ValidationError') {
                toast.error(error.message);
            } else {
                // Handle other potential errors
                toast.error('An error occurred. Please try again.');
            }
        }
    };
    return (
        <>
            <Modal className='' show={isOpenModal} size="sm" popup>
                <Modal.Body>
                    <div className="flex flex-col  justify-center -mb-2">
                        <h1 className='text-2xl mb-3 mt-3 font-bold text-[#13224d]'>GET START FOR FREE</h1>
                        <h3 className="mb-2 text-md font-normal text-[#333333] ">
                            Enter your mobile number to receive a free OTP for quick verification.
                        </h3>
                        <div className='flex flex-col items-center mt-1'>
                            <input
                                ref={mobileInputRef}
                                onChange={handleMobileChange}
                                className='custom-number-input border-[1px] border-slate-400 border-dotted w-full rounded-lg placeholder:text-xs flex items-center'
                                type="text"
                                placeholder='Enter Your Mobile'
                                value={mobile}
                                maxLength="10" // Enforces the max length
                            />
                            {error && <div className="text-red-600 text-sm mt-2">{error}</div>}
                        </div>
                        <div className="flex justify-end mt-4">
                            <Button color="light" size='sm' className='' onClick={() => {
                                navigate("/")
                                toggle()
                            }}>
                                Back
                            </Button>
                            <Button color="dark" size='sm' className='bg-primaryColor ms-2' onClick={mobileSend}>
                                Send
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


            <OtpVerifyVisitor isOpenverifyModal={openVerifyModal} toggleModal={toggleModal} mobile={mobile} />
        </>
    )

}

export default OtpSendVisitor