import React from 'react'
import icon from "../../assets/Images/SuccessModalApproval/icon.png"
import { Modal } from 'flowbite-react'
import { useNavigate } from 'react-router-dom'

const SuccessModalApproval = ({ openSuccess, toggleSuccess }) => {

    const navToDashbard = () => {
        const accessToken = localStorage.getItem('access-token')
        const refreshToken = localStorage.getItem('refreshToken')
        const roleName = localStorage.getItem('role')

        const newUrl = `https://devapp.chitbid.com/reconnect/?accessToken=${encodeURIComponent(accessToken)}&refreshToken=${encodeURIComponent(refreshToken)}&roleName=${encodeURIComponent(roleName)}`;
        // const newUrl = `http://localhost:3003/reconnect/?accessToken=${encodeURIComponent(accessToken)}&refreshToken=${encodeURIComponent(refreshToken)}&roleName=${encodeURIComponent(roleName)}`;

        console.log(newUrl);


        // Open the URL in a new tab
        window.open(newUrl, '_blank');
    }
    return (
        <>
            <Modal className='font-poppins' show={openSuccess} size="lg" popup >

                <Modal.Body className='bg-[#F3D8D5] rounded-md '>
                    <div className=' flex flex-col items-center'>
                        <img src={icon} className='w-full' alt="" />
                        <h2 className='text-2xl font-bold'>Congratulations!</h2>
                        <p className='text-sm mt-3 text-center mb-4'>"We have received your Company Register Details and will respond shortly. An admin will Verify your request & Approve Shortly."</p>
                        {/* <button className='px-3 py-2 border-[2px] border-primaryColor rounded-md bg-white'
                            onClick={() => {
                                navigate("/")
                                toggleSuccess()
                            }}>
                            Go to Home
                        </button> */}
                        <button className='px-3 py-2 border-[2px] border-primaryColor rounded-md bg-white'
                            onClick={navToDashbard}
                        >
                            Go to Dashboard
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SuccessModalApproval