import React, { useState } from 'react'
import App from '../App'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Footer from '../Components/Footer/Footer'
import Navbar from '../Components/Navbar/Navbar'
import FeaturesPageSingle from '../Containers/Feautrespage/FeaturesPageSingle'
import FeaturesPage from '../Containers/Feautrespage/FeaturesPage'
import AboutUs from '../Containers/AboutUs/AboutUs'
import RequestaDemo from '../Containers/RequestaDemo/RequestaDemo'
import ScrollToTop from '../ScrollToTop/ScrollToTop'
import StickyButton from '../Components/StickyButton/StickyButton'
import PageNotFound from '../Components/PageNotFound/PageNotFound'
import ContactUs from '../Containers/ContactUs/ContactUs'
import FeaturesBottom from '../Components/FeaturesBottom/FeaturesBottom'
import Drawer from '../Components/Drawer/Drawer'

const AppRoutes = () => {

const [theme,setTheme] = useState("light")

  return (
    <BrowserRouter>

    <ScrollToTop/>
    {/* <StickyButton theme={theme}/> */}

    <Navbar theme={theme} setTheme={setTheme}/>
    <Drawer theme={theme}/>

    <Routes>

        <Route path='/' element={<App  theme={theme}/>} />

        <Route path='/Features' element={<FeaturesPage theme={theme}/>} />
        <Route path='/Features/:id' element={<FeaturesPageSingle theme={theme}/>} />
        <Route path='/AboutUs' element={<AboutUs theme={theme} />} />
        <Route path='/RequestaDemo' element={<RequestaDemo theme={theme} />} />
        <Route path='/ContactUs' element={<ContactUs theme={theme} />} />

        <Route path='*' element={<PageNotFound/>} />

    </Routes>

    <Footer  theme={theme}/>
    <FeaturesBottom theme={theme}/>

</BrowserRouter>
  )
}

export default AppRoutes