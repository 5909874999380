import { Button, Modal } from 'flowbite-react'
import React, { useState } from 'react'
import OTPInput from 'react-otp-input'
import "../OtpModal/OtpModal.css"
import { mobileOTPSignup, mobileOTPSignUpVerify } from '../../api/auth'
import toast from 'react-hot-toast'
import FormModal from '../FormModal/FormModal'
import { useQuery } from 'react-query'
import { getProfile } from '../../api/companyCreation'


const OtpVerifyVisitor = ({ isOpenverifyModal, toggleModal, mobile, verifyOtp, toggleFormChitAd }) => {



    const [otp, setOtp] = useState();
    const [openForm, setOpenForm] = useState(false);

    const toggleForm = () => {
        setOpenForm(!openForm)
    }

    const otpTrigger = async () => {
        const data = {
            mobile: mobile,
            visitor: true,
        };
        if (mobile.length === 10) {
            const mobileStatus = await mobileOTPSignup(data);
            if (mobileStatus) {
                toast.success('OTP Resend Successfully!');
            }
        }
    }

    const otpVerify = async () => {
        const payload = {
            mobile: mobile,
            otp
        }
        const updateApi = await mobileOTPSignUpVerify(payload)
        if (updateApi) {
            toast.success('OTP Verified Successfully')
            const data = updateApi.data.result;
            localStorage.setItem('access-token', data.tokens.accessToken);
            localStorage.setItem('role', btoa(data.user.role.name));
            localStorage.setItem('refreshToken', data.tokens.refreshToken);
            localStorage.setItem('userId', data.user._id);
            localStorage.setItem('mobile', mobile);
            console.log(data);

            if (data?.currentStatus === 'registration_pending') {
                setOtp('')
                toggleModal ? toggleModal() : toggleFormChitAd()
                setOpenForm(true)
            } else {
                const accessToken = localStorage.getItem('access-token')
                const refreshToken = localStorage.getItem('refreshToken')
                const roleName = localStorage.getItem('role')
                const newUrl = `https://devapp.chitbid.com/reconnect/?accessToken=${encodeURIComponent(accessToken)}&refreshToken=${encodeURIComponent(refreshToken)}&roleName=${encodeURIComponent(roleName)}`;
                // const newUrl = `http://localhost:3003/reconnect/?accessToken=${encodeURIComponent(accessToken)}&refreshToken=${encodeURIComponent(refreshToken)}&roleName=${encodeURIComponent(roleName)}`;

                console.log(newUrl);


                // Open the URL in a new tab
                window.open(newUrl, '_blank');
            }
        }
    }


    return (
        <>
            <Modal className='z-[2000]' show={isOpenverifyModal || verifyOtp} style={{ width: 'auto' }} onClose={toggleModal || toggleFormChitAd} popup>

                <Modal.Body>
                    <div className="text-center p-6">
                        <h1 className='text-2xl mb-3 font-bold'>OTP Verification</h1>
                        <h3 className="mb-5 text-md font-normal text-gray-500 dark:text-gray-400">
                            Enter the OTP you received at <span className='text-navydark font-bold'>+91 {mobile}</span>
                        </h3>
                        <div className='flex justify-center'>
                            <OTPInput
                                value={otp}
                                onChange={(e) => setOtp(e)}
                                inputType="tel"
                                numInputs={6}
                                inputStyle="custom-otp-field"
                                renderInput={(props) => <input {...props} />}
                            />

                        </div>
                        <div className="flex justify-center mt-5">
                            <Button color="dark" size='sm' className='bg-primaryColor' disabled={!otp || otp.length !== 6} onClick={otpVerify}>
                                Verify
                            </Button>
                        </div>
                        <p className='text-sm mt-3 text-gray-500'>Didn't receive code? <span className='text-teal-600 underline cursor-pointer' onClick={otpTrigger}>Request again</span></p>
                    </div>
                </Modal.Body>
            </Modal>

            <FormModal isOpenForm={openForm} toggleForm={toggleForm} mobile={mobile} />
        </>
    )
}

export default OtpVerifyVisitor