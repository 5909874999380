import React, { useState, useEffect, useRef } from 'react';
import logo from '../../assets/Images/Logo/logo.png';
import arrow from '../../assets/Images/BannerSection/arrow.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as Sun } from "../../assets/Images/Mode Images/Sun.svg";
import { ReactComponent as Moon } from "../../assets/Images/Mode Images/Moon.svg";
import "./DarkMode.css";
import { Button } from 'flowbite-react';
import { CgChevronDown, CgChevronUp } from 'react-icons/cg';
import { FeaturesListData } from '../../Data/FeaturesListData';
import { BsArrowRight } from 'react-icons/bs';

import "./Navbar.css"

const Navbar = ({ theme, setTheme }) => {
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const ref = useRef()

  const toggle_mode = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const FeaturesRouteRegex = /^\/Features(\/[\w-&]+)?$/;

  useEffect(() => {
    setOpen(false);
  }, [location]);

// this is for when we click outside od dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);



  return (
    <>
      <div className="fixed top-0 left-0 z-50 w-full">

        <div className="p-[6px] 2xl:p-3 backdrop-filter backdrop-blur-sm"></div>

        <div className="px-[3%] xl:px-[5%]">
          <div
            className={`flex items-center justify-between h-[75px] backdrop-filter backdrop-blur-sm px-[3%] ${theme === 'dark' ? 'bg-[#17060347]' : 'bg-[#ffffffab]'
              } rounded-lg border-[0.1px] border-[#E3E5ED] relative`}
          >
            <Link to={'/'}>
            <div className='flex items-center'>
              
                <img className="h-auto w-12 md:h-14 md:w-14 sm:w-auto sm:h-16" src={logo} alt="Logo" />
             
              <h2 className={`ms-2 font-poppins text-xl md:text-2xl font-semibold ${theme === "dark" ? "text-white" : "text-primaryColor"}`}>CHITBID</h2>
            </div>
            </Link>
            <div className="lg:hidden">
              <button
                type="button"
                className="inline-flex p-2 items-center justify-center text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                onClick={() => setOpen(!open)}
              >
                <svg
                  className="w-8 h-8 md:w-10 md:h-10"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              </button>
            </div>

            <nav className="hidden space-x-10 lg:flex">
              <Link
                to="/"
                className={`text-base 
                  ${location.pathname === "/" ? `${theme === "dark" ? "font-bold text-white" : "font-bold text-primaryColor"}` : `${theme === "dark" ? "text-[#E3E5ED] hover:text-white" : "text-black hover:text-primaryColor"}`}`}
              >
                Home
              </Link>
              <Link
                to="/AboutUs"
                className={`text-base 
                  ${location.pathname === "/AboutUs" ? `${theme === "dark" ? "font-bold text-white" : "font-bold text-primaryColor"}` : `${theme === "dark" ? "text-[#E3E5ED] hover:text-white" : "text-black hover:text-primaryColor"}`}`}
              >
                About us
              </Link>

              <div
                ref={ref}
                className={`text-base  cursor-pointer
                  ${FeaturesRouteRegex.test(location.pathname) ? `${theme === "dark" ? "font-bold text-white" : "font-bold text-primaryColor"}` : `${theme === "dark" ? "text-[#E3E5ED] hover:text-white" : "text-black hover:text-primaryColor"}`}`}
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
                onClick={() => setIsOpen(!isOpen)}
              >
                <span className='flex   items-center '>
                  Our Features <span className='ms-1'>{isOpen ? <CgChevronUp className='mt-1 duration-300' /> : <CgChevronDown className='mt-1 duration-300' />}</span>
                </span>
                {isOpen && (
                  <div className={`absolute w-full  left-0 top-[73px] p-4 animate-slideInFast text-black font-poppins  grid grid-cols-12 rounded-lg shadow-lg z-10 ${theme === "dark" ? "bg-white" : "bg-white"}`}>

                    <div className='col-span-12'>
                      <div className='grid grid-cols-12 gap-y-6 gap-x-4'>
                        {FeaturesListData.map((item, i) => (
                          <div
                            onClick={() => {
                              navigate(`/Features/${item.id}`);
                              setIsOpen(false);
                            }}
                            key={i}
                            className='col-span-4 group'
                          >
                            <div className='flex items-start cursor-pointer'>
                              <img className='w-14 2xl:w-16' src={item.color_icon} alt="icons" />
                              <div className='ms-3 '>
                                <h1 className='text-sm 2xl:text-base font-semibold flex items-center'>{item.title} <BsArrowRight className={`${item.id ? "hidden group-hover:animate-slideLeftFast group-hover:block" : "hidden"} ms-2`} /> </h1>
                                <p className=' mt-[2px] text-[10px] 2xl:text-sm leading-4 text-[#777777]'>{item.description}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                  </div>
                )}
              </div>

              <Link
                to="/ContactUs"
                className={`text-base 
                  ${location.pathname === "/ContactUs" ? `${theme === "dark" ? "font-bold text-white" : "font-bold text-primaryColor"}` : `${theme === "dark" ? "text-[#E3E5ED] hover:text-white" : "text-black hover:text-primaryColor"}`}`}
              >
                Contact Us
              </Link>
            </nav>

            <div className="items-center justify-end hidden lg:inline-flex">
              <div className='flex flex-col justify-center items-center me-3'>
                <input
                  className='dark_mode_input'
                  type='checkbox'
                  checked={theme === "dark"}
                  id='darkmode-toggle'
                  onChange={toggle_mode}
                />
                <label className='dark_mode_label' htmlFor='darkmode-toggle'>
                  <Sun />
                  <Moon />
                </label>
              </div>
              <Link to="/RequestaDemo">
                <button
                  className={`flex items-center justify-center h-11 px-2 rounded-lg ${theme === 'dark' ? 'bg-primaryColor font-poppins hover:bg-[#c74132dd] text-white' : 'bg-primaryColor font-poppins hover:bg-[#c74132dd] text-white'
                    }`}
                >
                  Request a Demo <img src={arrow} className="w-6 h-6 text-white" alt="" />
                </button>
                {/* <button class="btn">Request a Demo</button> */}
              </Link>
            </div>
          </div>
        </div>
        
        {/* mobile view */}
        <div className={`${open ? 'block ease-out duration-200' : 'hidden'} absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden`}>
          <div className={` ${theme === "dark" ? "bg-[#170603ec] border-[1px] border-white rounded-md" : "bg-white"} divide-y-2 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 divide-gray-50`}>
            <div className=" pt-5 pb-6">
              <div className="px-5 pb-4 flex items-center justify-between">
                <div className='flex items-center'>
                  <img className="w-auto h-14" src={logo} alt="Workflow" />
                  <h2 className='text-xl text-primaryColor ms-2 font-poppins font-bold'>CHITBID</h2>
                </div>
                <div className="-mr-2">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                    onClick={() => setOpen(!open)}
                  >
                    <svg
                      className="w-7 h-7"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>

              <hr />

              <nav className="px-5 mt-5 flex flex-col ">
                <Link
                  to="/"
                  onClick={() => setOpen(!open)}
                  className={`text-base ${location.pathname === '/' ? 'font-bold' : 'text-[#E3E5ED]'
                    } ${theme === 'dark' ? 'text-white' : 'text-black hover:text-black/70'}`}
                >
                  Home
                </Link>
                <hr className='my-3' />
                <Link
                  to="/AboutUs"
                  onClick={() => setOpen(!open)}
                  className={`text-base ${location.pathname === '/AboutUs' ? 'font-bold' : 'text-[#E3E5ED]'
                    } ${theme === 'dark' ? 'text-white' : 'text-black hover:text-black/70'}`}
                >
                  About Us
                </Link>
                <hr className='my-3' />
                <Link
                  to="/Features"
                  onClick={() => setOpen(!open)}
                  className={`text-base ${FeaturesRouteRegex.test(location.pathname) ? 'font-bold' : 'text-[#E3E5ED]'
                    } ${theme === 'dark' ? 'text-white' : 'text-black hover:text-black/70'}`}
                >
                  Our Features
                </Link>
                <hr className='my-3' />
                <Link
                  to="/ContactUs"
                  onClick={() => setOpen(!open)}
                  className={`text-base ${location.pathname === '/ContactUs' ? 'font-bold' : 'text-[#E3E5ED]'
                    } ${theme === 'dark' ? 'text-white' : 'text-black hover:text-black/70'}`}
                >
                  Contact Us
                </Link>
                <hr className='my-3' />
              </nav>

              <div className=" inline-flex flex-col px-5 ">
                <div className='flex items-center'>
                  <h3 className={`me-2 ${theme === "dark" ? "text-white" : ""}`}>Mode </h3>
                  <div className='flex flex-col items-center' onClick={() => setOpen(!open)}>
                    <input
                      className='dark_mode_input'
                      type='checkbox'
                      checked={theme === "dark"}
                      id='darkmode-toggle'
                      onChange={toggle_mode}
                    />
                    <label className='dark_mode_label' htmlFor='darkmode-toggle'>
                      <Sun />
                      <Moon />
                    </label>
                  </div>
                </div>

                <div className='flex items-center justify-center mt-5'>
                  <Button onClick={() => setOpen(!open)} color="failure" className='h-10 xl:h-12 p-0 xl:pt-1 bg-primaryColor me-2 lg:me-5 hover:bg-primaryColor'>
                    Get Start For Free <img src={arrow} className='w-6 h-6 text-white' alt="" />
                  </Button>
                  <Button
                    onClick={
                      () => {
                        setOpen(!open)
                        navigate("/RequestaDemo")
                      }}
                    color="gray" className='h-10 xl:h-12 p-0 xl:pt-1'>
                    Request a Demo
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </>
  );
};

export default Navbar;
