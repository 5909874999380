import React, { useEffect, useState } from 'react';
import col from '../../assets/Images/Logo/col.png';
import cus from '../../assets/Images/Logo/customer-service_9759776 1.png';
import tar from "../../assets/Images/Logo/target-audience_11744835 1.png"
import arrow from "../../assets/Images/Logo/next_3114931 (1) 1.png"
import vedio from "../../assets/Images/Features/gif.mp4"
import phoneBg from "../../assets/Images/Features/phoneBg.png"
import { useNavigate } from 'react-router-dom';

import auction_icon from "../../assets/Images/FeaturesListData/auction_step_icon.svg"
import crm_icon from "../../assets/Images/OurFeaturesData/crm_icon.svg"
import ekyc_icon from "../../assets/Images/OurFeaturesData/ekyc_icon.svg"
import cm_icon from "../../assets/Images/OurFeaturesData/collection_icon.svg"
import ps_icon from "../../assets/Images/OurFeaturesData/ps_icon.svg"
import risk_icon from "../../assets/Images/OurFeaturesData/riskmanage_icon.svg"

import Lottie from 'react-lottie';


import auction_animation from "../../Json/Home/FeaturesHome/Live Auction Our Features.json" 
import ekyc_animation from "../../Json/Home/FeaturesHome/E-KYC Verification Our Features.json"
import crm_animation from "../../Json/Home/FeaturesHome/Lead CRM Our Features.json"
import cm_animation from "../../Json/Home/FeaturesHome/Collection Management.json"
import pm_animation from "../../Json/Home/FeaturesHome/Payment Settlement Feature.json"
import risk_animation from "../../Json/Home/FeaturesHome/Risk management Our Features.json"


const featuresData = [
    {   id : "liveAuction",
        title: "Live Auction",
        description: "Participate in our live auctions for a chance to win immediate payouts and maximize your savings",
        img: auction_icon,
        animination : auction_animation,
        link: "/Features",
        duartion: 11000,
    },
    {    id : "e-kycVerifiaction",
        title: "E-KYC Verification",
        description: "Complete your KYC process conveniently with our streamlined Online KYC services",
        img: ekyc_icon,
        animination : ekyc_animation,
        link: "/Features",
        duartion: 3000,
    },
    {   id : "leadCRM",
        title: "Lead CRM",
        description: "Enhance your lead management with our powerful Lead CRM system",
        img: crm_icon,
        animination : crm_animation,
        link: "/Features",
        duartion: 9000,
    },
    {   id : "collectionManagement",
        title: "Collection Management",
        description: "Optimize your collections process with our comprehensive Collection Management solutions",
        img: cm_icon,
        animination : cm_animation,
        link: "/Features",
        duartion: 7000,
    },
    {    id : "paymentSettlement",
        title: "Payment Settlement",
        description: "Streamline your financial transactions with our efficient Payment Settlement system",
        img: ps_icon,
        animination : pm_animation,
        link: "/Features",
        duartion: 5000,
    },
    {    id : "AICustomerDueReport",
        title: "AI for customer Due report",
        description: "Utilize AI-powered tools for accurate and timely customer due reports",
        img: risk_icon,
        animination : risk_animation,
        link: "/Features",
        duartion: 7000,
    },
];

function Features({theme}) {

const navigate=useNavigate()
const [currentIndex, setCurrentIndex] = useState(0);

useEffect(()=>{
    const { duartion } = featuresData[currentIndex];
    const interval = setInterval(()=>{
        setCurrentIndex((prevIndex) => (prevIndex + 1) % featuresData.length);
    },duartion);

    return () => clearInterval(interval);
},[])

const currentFeature = featuresData[currentIndex]

    return (
        <div className={`antialiased sans-serif ${theme === "dark" ? "bg-featuresBgDark" : "bg-featuresBgLight"}  bg-center text-gray-600 py-7 px-[8%] relative`}>
            <div className="container mx-auto ">
                <h2 className='mb-4 text-center'>
                    <span className={`text-2xl font-bold md:text-4xl ${theme === "dark" ? " text-transparent bg-clip-text bg-gradient-to-r from-[#E26353] via-[#FBD4CE] to-[#E26353]" : "text-black"}`}>Features</span>
                </h2>
                <p className={`max-w-2xl mx-auto mb-5 xl:mb-8 text-center ${theme === "dark" ? "text-[#E3E5ED]" : "text-[#333333]"} `}>
                Experience the simplicity and efficiency of CHITBID's advanced features, crafted to streamline your chit fund processes.
                </p>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-y-5 lg:gap-y-0 gap-x-5">
                    {/* Left Features */}
                    <div className="flex flex-col justify-between gap-y-3 2xl:gap-y-5">
                        {featuresData.slice(0,3).map((feature, index) => (
                           <div key={index} className={`p-4 rounded-lg shadow-md text-center group flex flex-col items-center justify-center 2xl:h-full transform transition-all duration-200 ease-in-out ${currentIndex === index ? "bg-[#13224D] text-white" : "bg-[#FEFBFA] text-gray-600"} ${theme === "dark" ? "" : "border-[1px] border-[#3333334f]"} hover:bg-[#13224d] hover:text-white`}>
                                <div className="flex justify-center items-center mb-4">
                                    <img src={feature.img} alt={feature.title}  className="  w-10 2xl:w-12" />
                                    <h3 className="text-xl font-bold ms-3">{feature.title}</h3>
                                </div>
                                <p className="mb-4 2xl:text-xl">
                                    {feature.description}
                                </p>
                                <div className='flex justify-center hover:cursor-pointer' 
                                onClick={()=>navigate(`${feature.link}/${feature.id}`)}>
                                    <p className="text-red-500 flex items-center">Learn More <img src={arrow} height={28} width={28} /></p> 
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Center Video */}
                    <div className="mx-auto flex justify-center items-center w-[70%] md:w-[80%] 2xl:w-[70%] ">
                     
                                <Lottie options={{loop: true, autoplay: true, animationData: currentFeature.animination}}  className="h-full w-full"/>        
                          
                    </div>

                    {/* Right Features */}
                    <div className="flex flex-col justify-between gap-y-3 md:gap-y-0 2xl:gap-y-5">
                        {featuresData.slice(3).map((feature, index) => (
                           <div key={index + 3} className={`p-4 rounded-lg shadow-md text-center 2xl:h-full flex flex-col justify-center transform transition-all duration-200 ease-in-out ${currentIndex === index + 3 ? "bg-[#13224D] text-white" : "bg-white "} ${theme === "dark" ? "" : "border-[1px] border-[#3333334f]"} hover:bg-[#13224d] hover:text-white`}>
                                <div className="flex items-center justify-center mb-4">
                                    <img src={feature.img} alt={feature.title} className="w-10 2xl:w-12 " />
                                    <h3 className=" text-lg 2xl:text-xl font-bold ms-3">{feature.title}</h3>
                                </div>
                                <p className="mb-4 2xl:text-xl">
                                    {feature.description}
                                </p>

                                
                                <div  className='flex justify-center hover:cursor-pointer' 
                                onClick={()=>navigate(`${feature.link}/${feature.id}`)}>
                                    
                                    <p className="text-red-500 flex items-center">Learn More <img src={arrow} height={28} width={28} /></p> 
                                </div>
                                

                            </div>
                        ))}
                    </div>
                </div>

             
                <div  className="flex justify-center text-center font-poppins mt-3 cursor-pointer"
                onClick={()=>navigate("/Features")}>
                    <a  className="flex justify-center items-center text-[10px] md:text-sm lg:text-base px-1 lg:px-4 py-1 lg:py-2  border-[0.5px] border-[#e3e5edb9] rounded-md  shadow-md">
                        <div className={`inline-flex items-center ${theme === "dark" ? "text-white" : "text-[#333333] bg-white"}`}>
                            Get a complete overview of our <span className='text-transparent bg-clip-text bg-gradient-to-r from-[#E26353] via-[#FBD4CE] to-[#903225] font-bold mx-1'>CHITBID</span>  Features
                            <img src={arrow} className='w-8 h-8' alt="" />
                        </div>
                    </a>
                </div>
               

            </div>
        </div>
    );
}

export default Features;
