
import onlinekyc_icon_black from "../assets/Images/FeaturesSinglePage/online_kyc_black.svg"

// live auction images
import auction_icon_black from "../assets/Images/FeaturesSinglePage/auction_icon_black.svg"
import auction_step_icon from "../assets/Images/FeaturesListData/auction_step_icon.svg"
import auction_step1_icon from "../assets/Images/FeaturesListData/auction_step1_icon.svg"
import auction_step2_icon from "../assets/Images/FeaturesListData/auction_step2_icon.svg"
import auction_step1_right_light_icon from "../assets/Images/FeaturesListData/auction_step1_right_icon_light.svg"
import auction_step1_right__dark_icon from "../assets/Images/FeaturesListData/auction_step1_right_icon_dark.svg"

import auction_step2_left__dark_icon from "../assets/Images/FeaturesListData/auction_step2_left_title_icon_dark.svg"
import auction_step2_left__light_icon from "../assets/Images/FeaturesListData/auction_step2_left_icon_light.svg"

import auction_step3_right__light_icon from "../assets/Images/FeaturesListData/auction_step3_right_icon_light.svg"
import auction_step3_right__dark_icon from "../assets/Images/FeaturesListData/auction_step3_right_icon_dark.svg"

import auction_headerImg from "../Json/FeaturesSinglePage/LiveAuction/live auction Banner.json"

// lead CRM Images
import leadcrm_headerImg from "../Json/FeaturesSinglePage/LeadCrm/Lead CRM Banner.json"

import leadCrm_icon from "../assets/Images/FeaturesListData/LeadCRM/lead_crm_icon.svg"
import leadCrm_step1 from "../assets/Images/FeaturesListData/LeadCRM/lead_crm_step1.svg"
import leadCrm_step2 from "../assets/Images/FeaturesListData/LeadCRM/lead_crm_step2.svg"
import leadCrm_step3 from "../assets/Images/FeaturesListData/LeadCRM/lead_crm_step3.svg"

import leadCrm_step1_right_icon_dark from "../assets/Images/FeaturesListData/LeadCRM/lead_step1_right_icon_dark.svg"
import leadCrm_step1_right_icon_light from "../assets/Images/FeaturesListData/LeadCRM/lead_step1_right_icon_light.svg"

import leadCrm_step2_left_icon_light from "../assets/Images/FeaturesListData/LeadCRM/lead_step2_left_icon_light.svg"
import leadCrm_step2_left_icon_dark from "../assets/Images/FeaturesListData/LeadCRM/lead_step2_left_icon_dark.svg"

import leadCrm_step3_right_icon_dark from "../assets/Images/FeaturesListData/LeadCRM/lead_step3_right_icon_dark.svg"
import leadCrm_step3_right_icon_light from "../assets/Images/FeaturesListData/LeadCRM/lead_step3_right_icon_light.svg"

// E-Kyc Verification Images
import ekyc_step3_icon from "../assets/Images/FeaturesListData/E-Kyc/ekyc_step3_icon.svg"

import ekyc_step1_right_icon_light from "../assets/Images/FeaturesListData/E-Kyc/e-kyc_step1_right_icon_light.svg"
import ekyc_step1_right_icon_dark from "../assets/Images/FeaturesListData/E-Kyc/e-kyc_step1_right_icon_dark.svg"

import ekyc_step2_left_icon_light from "../assets/Images/FeaturesListData/E-Kyc/e-kyc_step2_left_icon_light.svg"
import ekyc_step2_left_icon_dark from "../assets/Images/FeaturesListData/E-Kyc/e-kyc_step2_left_icon_dark.svg"

import ekyc_step3_right_icon_light from "../assets/Images/FeaturesListData/E-Kyc/e-kyc_step3_right_icon_light.svg"
import ekyc_step3_right_icon_dark from "../assets/Images/FeaturesListData/E-Kyc/e-kyc_step3_right_icon_dark.svg"

import ekyc_headerImg from "../Json/FeaturesSinglePage/E-KYC Verification/Online KYC Banner.json"

// Collection Management
import cm_icon from "../assets/Images/FeaturesListData/CollectionManagement/cm_icon.svg"

import cm_step1_icon from "../assets/Images/FeaturesListData/CollectionManagement/cm_step1_icon.svg"
import cm_step2_icon from "../assets/Images/FeaturesListData/CollectionManagement/cm_step2_icon.svg"

import cm_step1_right_icon_light from "../assets/Images/FeaturesListData/CollectionManagement/cm_step1_right_icon_light.svg"
import cm_step1_right_icon_dark from "../assets/Images/FeaturesListData/CollectionManagement/cm_step1_right_icon_dark.svg"

import cm_step2_left_icon_dark from "../assets/Images/FeaturesListData/CollectionManagement/cm_step2_left_icon_dark.svg"
import cm_step2_left_icon_light from "../assets/Images/FeaturesListData/CollectionManagement/cm_step2_left_icon_light.svg"

import cm_headerImg from "../Json/FeaturesSinglePage/Collection/collection Management Banner.json"

// Payment Settlement
import pm_icon from "../assets/Images/FeaturesListData/PaymentSettlement/pm_icon.svg"

import pm_process1_icon from "../assets/Images/FeaturesListData/PaymentSettlement/pm_process1_icon.svg"
import pm_process2_icon from "../assets/Images/FeaturesListData/PaymentSettlement/pm_process2_icon.svg"

import pm_step1_right_icon_light from "../assets/Images/FeaturesListData/PaymentSettlement/pm_step1_right_icon_light.svg"
import pm_step1_right_icon_dark from "../assets/Images/FeaturesListData/PaymentSettlement/pm_step1_right_icon_dark.svg"

import pm_step2_left_icon_dark from "../assets/Images/FeaturesListData/PaymentSettlement/pm_step2_left_icon_dark.svg"
import pm_step2_left_icon_light from "../assets/Images/FeaturesListData/PaymentSettlement/pm_step2_left_icon_light.svg"

import pm_headerImg from "../Json/FeaturesSinglePage/Sattlement/Payment Settlement Banner.json"

// Document Verification
import dv_icon from "../assets/Images/FeaturesListData/DocumentVerification/dv_icon.svg"

import dv_step3_icon from "../assets/Images/FeaturesListData/DocumentVerification/dv_step3_icon.svg"
import dv_step4_icon from "../assets/Images/FeaturesListData/DocumentVerification/dv_step4_icon.svg"
import dv_step1_right_icon_light from "../assets/Images/FeaturesListData/DocumentVerification/dv_step1_right_icon_light.svg"
import dv_step1_right_icon_dark from "../assets/Images/FeaturesListData/DocumentVerification/dv_step1_right_icon_dark.svg"

import dv_step2_left_icon_dark from "../assets/Images/FeaturesListData/DocumentVerification/dv_step2_left_icon_dark.svg"
import dv_step2_left_icon_light from "../assets/Images/FeaturesListData/DocumentVerification/dv_step2_left_icon_light.svg"

import dv_step3_right_icon_light from "../assets/Images/FeaturesListData/DocumentVerification/dv_step3_right_icon_light.svg"
import dv_step3_right_icon_dark from "../assets/Images/FeaturesListData/DocumentVerification/dv_step3_right_icon_dark.svg"

import dv_step4_left_icon_dark from "../assets/Images/FeaturesListData/DocumentVerification/dv_step4_left_icon_dark.svg"
import dv_step4_left_icon_light from "../assets/Images/FeaturesListData/DocumentVerification/dv_step4_left_icon_light.svg"

import dv_headerImg from "../Json/FeaturesSinglePage/Document Verification/Document Verification Banner.json"

// Risk Management
import risk_icon from "../assets/Images/FeaturesListData/RiskManagement/risk_icon.svg"

import risk_headerImg from "../Json/FeaturesSinglePage/DueReport/AI for customer Due report Banner.json"

import risk_step1_icon from "../assets/Images/FeaturesListData/RiskManagement/risk_step1_icon.svg"
import risk_step2_icon from "../assets/Images/FeaturesListData/RiskManagement/risk_step2_icon.svg"
import risk_step3_icon from "../assets/Images/FeaturesListData/RiskManagement/risk_step3_icon.svg"

import risk_step1_icon_light from "../assets/Images/FeaturesListData/RiskManagement/risk_step1_right_icon_light.svg"
import risk_step1_icon_dark from "../assets/Images/FeaturesListData/RiskManagement/risk_step1_right_icon_dark.svg"

import risk_step2_icon_dark from "../assets/Images/FeaturesListData/RiskManagement/risk_step2_left_icon_dark.svg"
import risk_step2_icon_light from "../assets/Images/FeaturesListData/RiskManagement/risk_step2_left_icon_light.svg"

import risk_step3_icon_dark from "../assets/Images/FeaturesListData/RiskManagement/risk_step3_right_icon_dark.svg"
import risk_step3_icon_light from "../assets/Images/FeaturesListData/RiskManagement/risk_step3_right_icon_light.svg"

// Report Managment
import report_icon from "../assets/Images/FeaturesListData/ReportManagment/report_icon.svg"

import report_headerImg from "../Json/FeaturesSinglePage/Report Management/report management Banner.json"

import report_step1_icon from "../assets/Images/FeaturesListData/ReportManagment/report_step1_icon.svg"
import report_step2_icon from "../assets/Images/FeaturesListData/ReportManagment/report_step2_icon.svg"
import report_step3_icon from "../assets/Images/FeaturesListData/ReportManagment/report_step3_icon.svg"

import report_step1_icon_light from "../assets/Images/FeaturesListData/ReportManagment/report_step1_icon_light.svg"
import report_step1_icon_dark from "../assets/Images/FeaturesListData/ReportManagment/report_step1_icon_dark.svg"

import report_step2_icon_dark from "../assets/Images/FeaturesListData/ReportManagment/report_step2_left_icon_dark.svg"
import report_step2_icon_light from "../assets/Images/FeaturesListData/ReportManagment/report_step2_left_icon_light.svg"

import report_step3_icon_light from "../assets/Images/FeaturesListData/ReportManagment/report_step3_right_icon_light.svg"
import report_step3_icon_dark from "../assets/Images/FeaturesListData/ReportManagment/report_step3_right_icon_dark.svg"

// Chit & Chat
import chitchat_icon from "../assets/Images/FeaturesListData/ChitChat/chitchat_icon.svg"

import chitchat_headerImg from "../Json/FeaturesSinglePage/ChatForum/Chat & CHit Forum Banner.json"

import chitchat_step1_icon from "../assets/Images/FeaturesListData/ChitChat/chitchat_step1_icon.svg"
import chitchat_step2_icon from "../assets/Images/FeaturesListData/ChitChat/chitchat_step2_icon.svg"
import chitchat_step3_icon from "../assets/Images/FeaturesListData/ChitChat/chitchat_step3_icon.svg"
import chitchat_step4_icon from "../assets/Images/FeaturesListData/ChitChat/chitchat_step4_icon.svg"

import chitchat_step1_icon_light from "../assets/Images/FeaturesListData/ChitChat/chitchat_step1_icon_right_light.svg"
import chitchat_step1_icon_dark from "../assets/Images/FeaturesListData/ChitChat/chitchat_step1_icon_right_dark.svg"

import chitchat_step2_icon_dark from "../assets/Images/FeaturesListData/ChitChat/chitchat_step2_icon_left_dark.svg"
import chitchat_step2_icon_light from "../assets/Images/FeaturesListData/ChitChat/chitchat_step2_icon_left_light.svg"

import chitchat_step3_icon_light from "../assets/Images/FeaturesListData/ChitChat/chitchat_step3_icon_right_light.svg"
import chitchat_step3_icon_dark from "../assets/Images/FeaturesListData/ChitChat/chitchat_step3_icon_right_dark.svg"

import chitchat_step4_icon_dark from "../assets/Images/FeaturesListData/ChitChat/chitchat_step4_icon_left_dark.svg"
import chitchat_step4_icon_light from "../assets/Images/FeaturesListData/ChitChat/chitchat_step4_icon_left_light.svg"


// Features Different Icons
import auction_feature_icon from "../assets/Images/FeaturesListData/auction_step_icon.svg"
import crm_feature_icon from "../assets/Images/OurFeaturesData/crm_icon.svg"
import ekyc_feature_icon from "../assets/Images/OurFeaturesData/ekyc_icon.svg"
import cm_feature_icon from "../assets/Images/OurFeaturesData/collection_icon.svg"
import ps_feature_icon from "../assets/Images/OurFeaturesData/ps_icon.svg"
import risk_feature_icon from "../assets/Images/OurFeaturesData/riskmanage_icon.svg"
import report_feature_icon from "../assets/Images/OurFeaturesData/reportmanage_icon.svg"
import dv_feature_icon from "../assets/Images/OurFeaturesData/finance_icon.svg"
import chitchat_feature_icon from "../assets/Images/OurFeaturesData/chit_chat_icon.svg"

// Features Color Icons For Hover
import auction_color_icon from "../assets/Images/FeaturesColorIcons/auction_color_icon.svg"
import crm_color_icon from "../assets/Images/FeaturesColorIcons/crm_color_icon.svg"
import ekyc_color_icon from "../assets/Images/FeaturesColorIcons/ekyc_color_icon.svg"
import cm_color_icon from "../assets/Images/FeaturesColorIcons/cm_color_icon.svg"
import ps_color_icon from "../assets/Images/FeaturesColorIcons/pm_color_icon.svg"
import risk_color_icon from "../assets/Images/FeaturesColorIcons/risk_color_icon.svg"
import report_color_icon from "../assets/Images/FeaturesColorIcons/report_color_icon.svg"
import dv_color_icon from "../assets/Images/FeaturesColorIcons/dv_color_icon.svg"
import chitchat_color_icon from "../assets/Images/FeaturesColorIcons/chitchat_color_icon.svg"

// LIVE auction json 
import auction_animi1 from "../Json/FeaturesSinglePage/LiveAuction/Fea Single View (LA) 1.json"
import auction_animi2 from "../Json/FeaturesSinglePage/LiveAuction/Fea Single View (LA) 2.json"
import auction_animi3 from "../Json/FeaturesSinglePage/LiveAuction/Fea Single View (LA) 3.json"

// ekyc json
import auction_ekyc1 from "../Json/FeaturesSinglePage/E-KYC Verification/Feature single (E-KYC)1.json"
import auction_ekyc2 from "../Json/FeaturesSinglePage/E-KYC Verification/Feature single (E-KYC)2.json"

//lead crm
import auction_leadcrm1 from "../Json/FeaturesSinglePage/LeadCrm/Fea Single View (CRM) 1.json"
import auction_leadcrm2 from "../Json/FeaturesSinglePage/LeadCrm/Fea Single View (CRM)2.json"
import auction_leadcrm3 from "../Json/FeaturesSinglePage/LeadCrm/Fea Single View (CRM)3.json"

//collection
import auction_collection1 from "../Json/FeaturesSinglePage/Collection/Feature single (Collection)1.json"
import auction_collection2 from "../Json/FeaturesSinglePage/Collection/Feature single (Collection)2.json"

//sattlement
import auction_payment1 from "../Json/FeaturesSinglePage/Sattlement/Feature single (Settlement)1.json"
import auction_payment2 from "../Json/FeaturesSinglePage/Sattlement/Feature single (Settlement)2.json"

//Due Report
import auction_risk1 from "../Json/FeaturesSinglePage/DueReport/Feature single (Risk points)1.json"
import auction_risk2 from "../Json/FeaturesSinglePage/DueReport/Feature single (Risk points)2.json"
import auction_risk3 from "../Json/FeaturesSinglePage/DueReport/Feature single (Risk points)3.json"

//chatChit
import auction_chat1 from "../Json/FeaturesSinglePage/ChatForum/Feature single (Chat)1.json"
import auction_chat2 from "../Json/FeaturesSinglePage/ChatForum/Feature single (Chat)2.json"
import auction_Forum1 from "../Json/FeaturesSinglePage/ChatForum/Feature single (Forum)1.json"
import auction_Forum2 from "../Json/FeaturesSinglePage/ChatForum/Feature single (Forum)2.json"

//report management
import auction_report1 from "../Json/FeaturesSinglePage/Report Management/Feature single (Report)1.json"
import auction_report2 from "../Json/FeaturesSinglePage/Report Management/Feature single (Report)2.json"
//document verification
import auction_verify1 from "../Json/FeaturesSinglePage/Document Verification/Feature single (Document)1.json"
import auction_verify2 from "../Json/FeaturesSinglePage/Document Verification/Feature single (Document)2.json"
import auction_verify3 from "../Json/FeaturesSinglePage/Document Verification/Feature single (Document)3.json"
export const FeaturesListData = [
  {
    id: "liveAuction",
    icon: auction_icon_black,
    feature_icon: auction_feature_icon,
    color_icon: auction_color_icon,
    title: "Live Auction",
    description: "Participate in our live auctions for a chance to win immediate payouts and maximize your savings",
    para: "By following these steps, you can increase your chances of winning a live auction and securing . Good luck and happy bidding",
    process: [
      {
        processIcon: auction_step_icon,
        title: "live Auction",
        para: "View upcoming and current auctions to find items of interest.Click on the auction you wish to join to enter the auction room."
      },
      {
        processIcon: auction_step1_icon,
        title: "Bit Auction participation",
        para: "View upcoming and current auctions to find items of interest.Click on the auction you wish to join to enter the auction room."
      },
      {
        processIcon: auction_step2_icon,
        title: "Bid Winning",
        para: "View upcoming and current auctions to find items of interest.Click on the auction you wish to join to enter the auction room."
      },
    ],
    processStep1: [
      {
        leftImg: auction_animi1,
        rightTitleImgLight: auction_step1_right_light_icon,
        rightTitleImgDark: auction_step1_right__dark_icon,
        rightTitle: "Join a community of enthusiastic bidders and share your auction experiences.",
        rightDesc: [
          {
            para: "Be online and ready at the auction’s start time."
          },
          {
            para: "View upcoming and current auctions to find items of interest."
          },
          {
            para: "Enable auto-bid to automatically place bids up to your maximum limit."
          },
        ]
      }
    ],
    processStep2: [
      {
        rightImg: auction_animi2,
        leftTitleImgLight: auction_step2_left__light_icon,
        leftTitleImgDark: auction_step2_left__dark_icon,
        titleImgWidth: "w-72",
        leftTitle: "you can successfully participate in live auctions and enjoy the excitement of real-time bidding.",
        leftDesc: [
          {
            para: "Enable auto-bid to automatically place bids up to your maximum limit."
          },
          {
            para: "Engage in our bid auctions to access lump sum funds and boost your financial flexibility."
          }
        ]
      }
    ],
    processStep3: [
      {
        leftImg: auction_animi3,
        rightTitleImgLight: auction_step3_right__light_icon,
        rightTitleImgDark: auction_step3_right__dark_icon,
        rightTitle: "Secure substantial payouts by winning bids in our chit fund and accelerate your financial growth!",
        rightDesc: [
          {
            para: "Secure substantial payouts by winning bids in our chit fund, giving you the financial boost needed to achieve your goals and accelerate your financial growth!"
          },
        ]
      }
    ],
    headerTopImg: auction_headerImg,
    headerTitle: "Experience the thrill of live auctions with our real-time bidding system",
    headerPara: "Participate in auctions from any device, whether you're at home or on the go."
  },
  {
    id: "e-kycVerifiaction",
    icon: onlinekyc_icon_black,
    feature_icon: ekyc_feature_icon,
    color_icon: ekyc_color_icon,
    title: "E-KYC Verification",
    description: "Complete your KYC process conveniently with our streamlined Online KYC services",
    para: "Online KYC process: Remote verification of customer identities using digital documentation and sometimes biometric methods, ensuring compliance and reducing fraud risks.",
    process: [
      {
        processIcon: auction_step_icon,
        title: "Account Details",
        para: "Comprehensive information summarizing financial or user account specifics."
      },
      {
        processIcon: auction_step1_icon,
        title: "Documents",
        para: "Written or digital records containing information, often used for verification, communication, or legal purposes."
      },
      // {
      //   processIcon: ekyc_step3_icon,
      //   title: "Verification Call",
      //   para: "Telephone conversation used to confirm details, authenticate identities, typically part of a verification process."
      // },
    ],
    processStep1: [
      {
        leftImg: auction_ekyc1,
        rightTitleImgLight: ekyc_step1_right_icon_light,
        rightTitleImgDark: ekyc_step1_right_icon_dark,
        rightTitle: "Account Details Management",
        rightDesc: [
          {
            para: "Be online and readProviding detailed information about account holders, including personal details, contact information, and account types.y at the auction’s start time."
          },
          {
            para: " Recording and maintaining a chronological list of all transactions, including deposits, withdrawals, transfers, and payments."
          },
          {
            para: "Enable auto-bid to automatically place bids up to your maximum limit."
          },
        ]
      }
    ],
    processStep2: [
      {
        rightImg: auction_ekyc2,
        leftTitleImgLight: ekyc_step2_left_icon_light,
        leftTitleImgDark: ekyc_step2_left_icon_dark,
        titleImgWidth: "w-40",
        leftTitle: "Documents Management",
        leftDesc: [
          {
            para: "Managing various types of documents including contracts, reports, invoices, and correspondence."
          },
          {
            para: "Organizing documents in a structured manner for easy retrieval using indexing, categorization, or document management systems."
          },
          {
            para: "Implementing security protocols such as encryption, access controls, and backup procedures to protect sensitive information."
          }
        ]
      }
    ],
    // processStep3: [
    //   {
    //     leftImg: auction_animi1,
    //     rightTitleImgLight: ekyc_step3_right_icon_light,
    //     rightTitleImgDark: ekyc_step3_right_icon_dark,
    //     rightTitle: "Verification Call Process",
    //     rightDesc: [
    //       {
    //         para: "Verifying the identity of individuals through personal information verification or biometric authentication during the call."
    //       },
    //       {
    //         para: "Confirming specific details such as account information, transaction history, or personal credentials to ensure accuracy and security."
    //       },
    //       {
    //         para: "Clearly stating the purpose of the verification call and informing the individual about the information being verified."
    //       }
    //     ]
    //   }
    // ],
    headerTopImg: ekyc_headerImg,
    headerTitle: "Efficient Online KYC: Streamlining Identity Verification for Digital Transactions",
    headerPara: "The Online KYC process involves the digital verification of a customer's identity using electronic documentation and secure authentication methods"
  },
  {
    id: "leadCRM",
    icon: leadCrm_icon,
    feature_icon: crm_feature_icon,
    color_icon: crm_color_icon,
    title: "Lead CRM",
    description: "Enhance your lead management with our powerful Lead CRM system",
    para: "By following these steps, you can increase your chances of winning a live auction and securing . Good luck and happy bidding",
    process: [
      {
        processIcon: leadCrm_step1,
        title: "Followup",
        para: "Ensure timely and consistent communication with our streamlined follow-up system."
      },
      {
        processIcon: leadCrm_step2,
        title: "Total Leads",
        para: "Participate in our chit fund auctions to win lump sum payouts while growing your savings efficiently."
      },
      {
        processIcon: leadCrm_step3,
        title: "Schedule",
        para: "Effortlessly organize and manage your activities with our comprehensive scheduling tool."
      },
    ],
    processStep1: [
      {
        leftImg: auction_leadcrm1,
        rightTitleImgLight: leadCrm_step1_right_icon_light,
        rightTitleImgDark: leadCrm_step1_right_icon_dark,
        rightTitle: "Ensuring Seamless Continuity and Engagement",
        rightDesc: [
          {
            para: "Automated Reminders: Set up automatic reminders for follow-up tasks and appointments."
          },
          {
            para: "Task Management: Keep track of tasks and set priorities to manage follow-ups efficiently."
          },
          {
            para: "Enhanced Productivity: Automates and organizes follow-up tasks, freeing up time for more critical activities."
          },
        ]
      }
    ],
    processStep2: [
      {
        rightImg: auction_leadcrm2,
        leftTitleImgLight: leadCrm_step2_left_icon_light,
        leftTitleImgDark: leadCrm_step2_left_icon_dark,
        titleImgWidth: "w-[180px]",
        leftTitle: "Maximizing Your Sales Potential",
        leftDesc: [
          {
            para: "Improved Lead Quality: By capturing and scoring leads effectively, focus your efforts on high-quality prospects."
          },
          {
            para: "Enhanced Efficiency: Automate repetitive tasks and streamline lead management to save time and resources."
          },
          {
            para: "Scalable Solution: Adapt Total Leads to grow with your business, handling increasing volumes of leads seamlessly."
          }
        ]
      }
    ],
    processStep3: [
      {
        leftImg: auction_leadcrm3,
        rightTitleImgLight: leadCrm_step3_right_icon_light,
        rightTitleImgDark: leadCrm_step3_right_icon_dark,
        rightTitle: "Streamlining Your Workflow and Tracking Progress",
        rightDesc: [
          {
            para: "Activity History: Maintain a detailed log of all activities, interactions, and changes, providing a complete overview of your workflow."
          },
          {
            para: "Task Scheduling: Easily schedule tasks and set reminders to ensure timely completion."
          },
        ]
      }
    ],
    headerTopImg: leadcrm_headerImg,
    headerTitle: "Lead CRM Optimization: Streamlining Acquisition to Conversion",
    headerPara: "Lead CRM process involves systematically managing leads from acquisition to conversion, optimizing customer acquisition strategies"
  },
  {
    id: "collectionManagement",
    icon: cm_icon,
    feature_icon: cm_feature_icon,
    color_icon: cm_color_icon,
    title: "Collection Management",
    description: "Optimize your collections process with our comprehensive Collection Management solutions",
    para: "By following these steps, you can increase your chances of winning a live auction and securing . Good luck and happy bidding",
    process: [
      {
        processIcon: cm_step1_icon,
        title: "List Out Payment Pending's",
        para: "View upcoming and current auctions to find items of interest.Click on the auction you wish to join to enter the auction room."
      },
      {
        processIcon: cm_step2_icon,
        title: "Collection Process",
        para: "View upcoming and current auctions to find items of interest.Click on the auction you wish to join to enter the auction room."
      },
    ],
    processStep1: [
      {
        leftImg: auction_collection1,
        rightTitleImgLight: cm_step1_right_icon_light,
        rightTitleImgDark: cm_step1_right_icon_dark,
        rightTitle: "Ensuring Timely Follow-up and Resolution",
        rightDesc: [
          {
            para: "Refers to a transaction where the payment process has been initiated but not yet completed, typically awaiting final authorization or processing."
          },
          {
            para: "Proactively contacting stakeholders or customers within a specified timeframe."
          },
          {
            para: "Utilizing analytical and critical thinking skills to identify root causes of issues ."
          },
        ]
      }
    ],
    processStep2: [
      {
        rightImg: auction_collection2,
        leftTitleImgLight: cm_step2_left_icon_light,
        leftTitleImgDark: cm_step2_left_icon_dark,
        titleImgWidth: "w-72",
        leftTitle: "Strategies for Efficient Debt Recovery",
        leftDesc: [
          {
            para: "Initiating communication with debtors to remind them of overdue payments and discuss repayment options."
          },
          {
            para: "Implementing automated reminders and scheduled follow-ups to maintain communication and monitor payment progress."
          },
          {
            para: "Maintaining accurate records of all interactions, agreements, and payment histories for transparency and compliance purposes"
          }
        ]
      }
    ],
    headerTopImg: cm_headerImg,
    headerTitle: "Monitoring and recording outstanding debts and payments owed to the organization.",
    headerPara: "Strategic methods to ensure timely retrieval of payments and debts, optimizing cash flow and client relations."
  },
  {
    id: "paymentSettlement",
    icon: pm_icon,
    feature_icon: ps_feature_icon,
    color_icon: ps_color_icon,
    title: "Payment Settlement",
    description: "Streamline your financial transactions with our efficient Payment Settlement system",
    para: "Ensure timely and accurate financial transactions with our streamlined Payment Settlement process.",
    process: [
      {
        processIcon: pm_process1_icon,
        title: "Auction Winners",
        para: "View upcoming and current auctions to find items of interest.Click on the auction you wish to join to enter the auction room."
      },
      {
        processIcon: pm_process2_icon,
        title: "Processed To Pay",
        para: "View upcoming and current auctions to find items of interest.Click on the auction you wish to join to enter the auction room."
      }
    ],
    processStep1: [
      {
        leftImg: auction_payment1,
        rightTitleImgLight: pm_step1_right_icon_light,
        rightTitleImgDark: pm_step1_right_icon_dark,
        rightTitle: "Managing Auction Winners",
        rightDesc: [
          {
            para: "Clearly identifying the names or identities of auction winners."
          },
          {
            para: "Providing descriptions of the items, services, or properties won by each auction winner."
          },
          {
            para: "Displaying the winning bid amounts or prices achieved in the auction process."
          },
          {
            para: "Including any necessary legal disclaimers or terms related to the auction process and winners' responsibilities."
          },
        ]
      }
    ],
    processStep2: [
      {
        rightImg: auction_payment2,
        leftTitleImgLight: pm_step2_left_icon_light,
        leftTitleImgDark: pm_step2_left_icon_dark,
        titleImgWidth: "w-64",
        leftTitle: "Proceed to Pay Process",
        leftDesc: [
          {
            para: "Displaying a summary of selected items or services with corresponding prices."
          },
          {
            para: " Providing various payment methods (credit card, debit card, net banking, etc.) for customer convenience."
          },
          {
            para: "Ensuring secure encryption and authentication protocols to safeguard payment information."
          }
        ]
      }
    ],
    headerTopImg: pm_headerImg,
    headerTitle: "The finalization and transfer of funds or assets between parties.",
    headerPara: "Payment settlement: The process of finalizing and transferring funds or assets between parties after a transaction."
  },
  {
    id: "AICustomerDueReport",
    icon: risk_icon,
    feature_icon: risk_feature_icon,
    color_icon: risk_color_icon,
    title: "AI for Customer Due Report",
    description: "Utilize AI-powered tools for accurate and timely customer due reports",
    para: "By following these steps, you can increase your chances of winning a live auction and securing . Good luck and happy bidding",
    process: [
      {
        processIcon: risk_step1_icon,
        title: "Due amount pay",
        para: "Efficiently manage and settle outstanding payments through our secure, user-friendly platform."
      },
      {
        processIcon: risk_step2_icon,
        title: "Points added",
        para: "Accumulate points seamlessly with every transaction for exclusive rewards and benefits."
      },
      {
        processIcon: risk_step3_icon,
        title: "Report Management",
        para: "Comprehensive report providing detailed insights and analysis on specific subject or area"
      },
    ],
    processStep1: [
      {
        leftImg: auction_risk1,
        rightTitleImgLight: risk_step1_icon_light,
        rightTitleImgDark: risk_step1_icon_dark,
        rightTitle: "ClearPay: Streamlining Outstanding Payments",
        rightDesc: [
          {
            para: "Allows users to monitor the status of outstanding payments in real-time."
          },
          {
            para: "Sends automated reminders to users for overdue payments."
          },
          {
            para: "Offers secure online payment methods for settling due amounts."
          },
        ]
      }
    ],
    processStep2: [
      {
        rightImg: auction_risk2,
        leftTitleImgLight: risk_step2_icon_light,
        leftTitleImgDark: risk_step2_icon_dark,
        titleImgWidth: "w-48",
        leftTitle: "PointsPlus: Earn and Manage Loyalty Points",
        leftDesc: [
          {
            para: "Earn points with every purchase or specified action."
          },
          {
            para: "Receive special offers and promotions based on point status."
          },
          {
            para: "Access points and rewards across various devices and platforms."
          }
        ]
      }
    ],
    processStep3: [
      {
        leftImg: auction_risk3,
        rightTitleImgLight: risk_step3_icon_light,
        rightTitleImgDark: risk_step3_icon_dark,
        rightTitle: "ReportMaster: Streamlining Business Insights",
        rightDesc: [
          {
            para: "Easily generate customized reports tailored to specific needs and preferences."
          },
          {
            para: "Securely store all reports in a centralized repository for easy access and organization."
          },
          {
            para: "Automate the generation and delivery of reports on a predefined schedule."
          },
        ]
      }
    ],
    headerTopImg: risk_headerImg,
    headerTitle: "Proactive Risk Management: Safeguarding Business Continuity",
    headerPara: "Risk management involves identifying, assessing, and prioritizing potential risks to an organization, followed by the implementation of strategies to minimize."
  },
  {
    id: "ReportManagement",
    icon: report_icon,
    feature_icon: report_feature_icon,
    color_icon: report_color_icon,
    title: "Report Management",
    description: "Utilize AI-powered tools for accurate and timely customer due reports",
    para: "By following these steps, you can increase your chances of winning a live auction and securing . Good luck and happy bidding",
    process: [
      {
        processIcon: report_step1_icon,
        title: "Overall Collection",
        para: "An exchange or transfer of goods, services, or funds between two or more parties, typically documented for financial and record-keeping purposes."
      },
      {
        processIcon: report_step2_icon,
        title: "Overall Settlement",
        para: "An exchange or transfer of goods, services, or funds between two or more parties, typically documented for financial and record-keeping purposes."
      },
      // {
      //   processIcon: report_step3_icon,
      //   title: "Overall Receipt Download",
      //   para: "An exchange or transfer of goods, services, or funds between two or more parties, typically documented for financial and record-keeping purposes."
      // },
    ],
    processStep1: [
      {
        leftImg: auction_report1,
        rightTitleImgLight: report_step1_icon_light,
        rightTitleImgDark: report_step1_icon_dark,
        rightTitle: "Comprehensive Collections: Maximizing Asset Recovery and Efficiency",
        rightDesc: [
          {
            para: "Uses advanced software to monitor and track outstanding debts and payments in real-time."
          },
          {
            para: "Classifies customers based on risk profiles and payment behaviors for targeted collection strategies."
          },
          {
            para: "Engages debtors through various channels, including emails, SMS, phone calls, and postal mail, ensuring effective communication."
          },
        ]
      }
    ],
    processStep2: [
      {
        rightImg: auction_report2,
        leftTitleImgLight: report_step2_icon_light,
        leftTitleImgDark: report_step2_icon_dark,
        titleImgWidth: "w-72",
        leftTitle: "Holistic Settlement Solutions: Streamlining Financial Resolutions",
        leftDesc: [
          {
            para: "Uses algorithms to accurately calculate settlement amounts, interest, and penalties."
          },
          {
            para: "Offers various payment methods, including lump-sum payments, installments, and deferred payment plans."
          },
          {
            para: "Allows customization of settlement agreements based on individual debtor's financial situations."
          }
        ]
      }
    ],
    // processStep3: [
    //   {
    //     leftImg: auction_animi1,
    //     rightTitleImgLight: report_step3_icon_light,
    //     rightTitleImgDark: report_step3_icon_dark,
    //     rightTitle: "Instant Receipt Download: Seamless Access to Transaction Records",
    //     rightDesc: [
    //       {
    //         para: "Provides instant access to downloadable receipts upon transaction completion."
    //       },
    //       {
    //         para: " Reviewing physical documents for authenticity, completeness, and accuracy according to established criteria"
    //       },
    //       {
    //         para: " Reviewing physical documents for authenticity, completeness, and accuracy according to established criteria"
    //       },
    //     ]
    //   }
    // ],
    headerTopImg: report_headerImg,
    headerTitle: "Streamlined Reporting: Enhancing Organizational Data Governance.",
    headerPara: "Enables users to create reports from various data sources, often through customizable templates or predefined formats."
  },
  {
    id: "DocumentVerification",
    icon: dv_icon,
    feature_icon: dv_feature_icon,
    color_icon: dv_color_icon,
    title: "Document Verifiaction",
    description: "Utilize AI-powered tools for accurate and timely customer due reports",
    para: "The process of confirming the authenticity, accuracy, and validity of submitted documents, typically for compliance, legal, or identity verification purposes.",
    process: [
      {
        processIcon: auction_step_icon,
        title: "Schedule",
        para: "Schedule allows you to efficiently plan and manage your meetings, events, and tasks."
      },
      {
        processIcon: auction_step1_icon,
        title: "Request",
        para: "Request offers a streamlined process to accept or reschedule appointments and tasks with ease."
      },
      {
        processIcon: dv_step3_icon,
        title: "Offline Verification",
        para: "Offline Verification ensures secure and reliable in-person validation of documents and information."
      },
      // {
      //   processIcon: dv_step4_icon,
      //   title: "Admin Verification",
      //   para: "Admin Verification provides an additional layer of security through thorough review and approval by administrators."
      // },
    ],
    processStep1: [
      {
        leftImg: auction_verify1,
        rightTitleImgLight: dv_step1_right_icon_light,
        rightTitleImgDark: dv_step1_right_icon_dark,
        rightTitle: "Quarterly Review Meetings",
        rightDesc: [
          {
            para: "Automated Reminders: Set up automatic reminders for follow-up tasks and appointments."
          },
          {
            para: "Task Management: Keep track of tasks and set priorities to manage follow-ups efficiently."
          },
          {
            para: "Enhanced Productivity: Automates and organizes follow-up tasks, freeing up time for more critical activities."
          },
        ]
      }
    ],
    processStep2: [
      {
        rightImg: auction_verify2,
        leftTitleImgLight: dv_step2_left_icon_light,
        leftTitleImgDark: dv_step2_left_icon_dark,
        titleImgWidth: "w-52",
        leftTitle: "Document Verification Process",
        leftDesc: [
          {
            para: "Receiving and collecting documents from applicants or stakeholders for verification purposes."
          },
          {
            para: "xamining documents to ensure they meet specified criteria, such as authenticity, completeness, and relevance."
          },
          {
            para: "Utilizing various verification methods, including cross-referencing with databases, contacting issuing authorities, and conducting background checks."
          }
        ]
      }
    ],
    processStep3: [
      {
        leftImg: auction_verify3,
        rightTitleImgLight: dv_step3_right_icon_light,
        rightTitleImgDark: dv_step3_right_icon_dark,
        rightTitle: "Offline Identity Verification Methods",
        rightDesc: [
          {
            para: "Conducting verification procedures physically at designated locations or through authorized personnel."
          },
          {
            para: " Reviewing physical documents for authenticity, completeness, and accuracy according to established criteria"
          },
        ]
      }
    ],
    // processStep4: [
    //   {
    //     rightImg: auction_animi1,
    //     leftTitleImgLight: dv_step4_left_icon_light,
    //     leftTitleImgDark: dv_step4_left_icon_dark,
    //     leftTitle: "Admin Verification Process in Organizational Security",
    //     leftDesc: [
    //       {
    //         para: "Verifying the identity of administrative users through official identification documents or identity verification processes."
    //       },
    //       {
    //         para: " Validating the credentials, qualifications, and professional background of administrative personnel to ensure they meet job requirements."
    //       },
    //     ]
    //   }
    // ],
    headerTopImg: dv_headerImg,
    headerTitle: "Secure Document Verification: Ensuring Trust and Compliance",
    headerPara: "Document verification involves the systematic process of validating the authenticity, accuracy, and integrity of various types of documents"
  },
  {
    id: "Chat&ChitForum",
    icon: chitchat_icon,
    feature_icon: chitchat_feature_icon,
    color_icon: chitchat_color_icon,
    title: "Chat & Chit Forum",
    description: "Streamline your financial transactions with our efficient Payment Settlement system",
    para: "By following these steps, you can increase your chances of winning a live auction and securing . Good luck and happy bidding",
    process: [
      {
        processIcon: chitchat_step1_icon,
        title: "Forum Creation",
        para: "View upcoming and current auctions to find items of interest.Click on the auction you wish to join to enter the auction room."
      },
      {
        processIcon: chitchat_step2_icon,
        title: "Regular Updates",
        para: "View upcoming and current auctions to find items of interest.Click on the auction you wish to join to enter the auction room."
      },
      {
        processIcon: chitchat_step3_icon,
        title: "Direct Chats",
        para: "View upcoming and current auctions to find items of interest.Click on the auction you wish to join to enter the auction room."
      },
      {
        processIcon: chitchat_step4_icon,
        title: "Group Chats",
        para: "View upcoming and current auctions to find items of interest.Click on the auction you wish to join to enter the auction room."
      },
    ],
    processStep1: [
      {
        leftImg: auction_chat1,
        rightTitleImgLight: chitchat_step1_icon_light,
        rightTitleImgDark: chitchat_step1_icon_dark,
        rightTitle: "SocialMedia Spark: Amplify Your Online Presence",
        rightDesc: [
          {
            para: "Plan and schedule posts in advance across multiple platforms."
          },
          {
            para: "Automate the posting process to ensure consistent content delivery."
          },
          {
            para: "Enable team members to collaborate on content creation and approval workflows."
          },
        ]
      }
    ],
    processStep2: [
      {
        rightImg: auction_chat2,
        leftTitleImgLight: chitchat_step2_icon_light,
        leftTitleImgDark: chitchat_step2_icon_dark,
        titleImgWidth: "w-48 md:w-72",
        leftTitle: "Continuous Insight Updates: Staying Ahead with Real-Time Information",
        leftDesc: [
          {
            para: "Receive timely alerts and updates via email, SMS, or in-app notifications."
          },
          {
            para: "Ensure all information is up-to-date with automatic synchronization across platforms."
          },
          {
            para: " Set update intervals based on user preferences—hourly, daily, weekly, etc."
          }
        ]
      }
    ],
    processStep3: [
      {
        leftImg: auction_Forum1,
        rightTitleImgLight: chitchat_step3_icon_light,
        rightTitleImgDark: chitchat_step3_icon_dark,
        rightTitle: "DirectConnect: Seamless One-on-One Communication",
        rightDesc: [
          {
            para: "Enables real-time messaging between users for quick communication."
          },
          {
            para: "Ensures messages are encrypted to maintain privacy and security."
          },
          {
            para: "Allows users to share files, documents, and media within the chat interface."
          },
        ]
      }
    ],
    processStep4: [
      {
        rightImg: auction_Forum2,
        leftTitleImgLight: chitchat_step4_icon_light,
        leftTitleImgDark: chitchat_step4_icon_dark,
        leftTitle: "CollabHub: Empowering Team Collaboration",
        leftDesc: [
          {
            para: "Facilitates discussions among multiple users simultaneously."
          },
          {
            para: "Organizes chats into channels based on specific topics or projects."
          },
          {
            para: "Allows sharing of files, documents, and media within the group chat."
          },
        ]
      }
    ],
    headerTopImg: chitchat_headerImg,
    headerTitle: "Connecting Communities for Financial Conversations",
    headerPara: "It is a platform fostering discussions among chit fund professionals, facilitating networking, knowledge sharing, and collaboration within the chit fund industry."
  }
]
